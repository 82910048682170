@charset 'utf-8';
@import 'color';
@import 'mixin';
@import 'settings';
@import 'foundation';
@import 'motion-ui';
@import 'components/burger';
@import 'components/slick';
@import 'components/slick-theme';
@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
// @include foundation-typography;
// @include foundation-button;
// @include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;
* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

ul{
    margin-left: auto;
}



body {
    color: $noir;
    font-family: '';
    font-weight: 300;
    /*@include font-size();*/
}

main {
    display: block;
}

.h1_hidden {
    font-size: 0px !important;
    text-indent: -99999999px !important;
}

.slick-prev:before,
.slick-next:before {
    color: $primary;
}

.slick-next {
    right: 0px;
    top: 30%;
}

.slick-prev {
    left: 0px;
    top: 30%;
}

.slick-slide {
    outline: none;
}

.bold {
    font-weight: 700;
}

.row_100 {
    max-width: 80%!important;
}

.row_200 {
    max-width: 100%!important;
}

.width_100 {
    width: 100%!important;
}

.aligne_center {
    text-align: center;
}

.margin_section {
    margin-top: 70px!important;
    margin-bottom: 70px!important;
}

.padding_section {
    padding-top: 70px!important;
    padding-bottom: 70px!important;
}

.clear {
    clear: both;
}

.parent {
    position: relative;
    width: 50%;
    height: 200px;
    margin: 20px auto;
}

.relative{

}

.child {
    padding: 20px;
    &.both {
        @include center;
    }
    &.horizontal {
        @include center(true, false);
    }
    &.vertical {
        @include center(false, true);
    }
}

h1,
h2,
h3,
h4 h5,
h6 {}

h1 {
    /*@include font-size();
        font-family: '';
        color: ;*/
    }

    h2 {
    /*@include font-size();
        font-family: '';
        text-transform: ;
        color:;*/
    }

    h3 {
    /*@include font-size();
        font-family: '';
        color: ;*/
    }

    h4 {
    /*@include font-size();
        font-family: '';*/
    }

    h5 {
    /*@include font-size();
        font-family: '';*/
    }

    li {
        list-style: none!important;
    }



    #404 {
        header {
            min-height: 100%;
        }
    }

    #container{
        overflow: hidden!important;
    }

    #cookieChoiceInfo{
        top:  auto!important;
        bottom: 0!important;
        z-index: 9999999!important;
        font-size: 16px;
        font-family: aleo-light, sans-serif!important;
    }


/*1.5 - perfect fifth
Base Size 22px = 2.2rem
*/

/* Selection */

::-webkit-selection {
    background: $primary;
    color: white;
}

::-moz-selection {
    background: $primary;
    color: white;
}

::-o-selection {
    background: $primary;
    color: white;
}

::-ms-selection {
    background: $primary;
    color: white;
}

::selection {
    background: $primary;
    color: white;
}

/* END Selection */


/* ----------------------------------------------------- FONT ----------------------------------------------------- */

@font-face {
    font-family: 'aleo-bold';
    src: url('../font/aleo/aleo-bold-webfont.eot');
    src: url('../font/aleo/aleo-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../font/aleo/aleo-bold-webfont.woff') format('woff'),
    url('../font/aleo/aleo-bold-webfont.ttf') format('truetype'),
    url('../font/aleo/aleo-bold-webfont.svg#aleo-bold') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'aleo-light';
    src: url('../font/aleo/aleo-light-webfont.eot');
    src: url('../font/aleo/aleo-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../font/aleo/aleo-light-webfont.woff') format('woff'),
    url('../font/aleo/aleo-light-webfont.ttf') format('truetype'),
    url('../font/aleo/aleo-light-webfont.svg#aleo-light') format('svg');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'aleo-regular';
    src: url('../font/aleo/aleo-regular-webfont.eot');
    src: url('../font/aleo/aleo-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../font/aleo/aleo-regular-webfont.woff') format('woff'),
    url('../font/aleo/aleo-regular-webfont.ttf') format('truetype'),
    url('../font/aleo/aleo-regular-webfont.svg#aleo-regular') format('svg');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'lato-light';
    src: url('../font/lato/lato-light-webfont.eot');
    src: url('../font/lato/lato-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../font/lato/lato-light-webfont.woff') format('woff'),
    url('../font/lato/lato-light-webfont.ttf') format('truetype'),
    url('../font/lato/lato-light-webfont.svg#lato-light') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'lato';
    src: url('../font/lato/lato-regular-webfont.eot');
    src: url('../font/lato/lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../font/lato/lato-regular-webfont.woff') format('woff'),
    url('../font/lato/lato-regular-webfont.ttf') format('truetype'),
    url('../font/lato/lato-regular-webfont.svg#lato-regular') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'lato-bold';
    src: url('../font/lato/lato-bold-webfont.eot');
    src: url('../font/lato/lato-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../font/lato/lato-bold-webfont.woff') format('woff'),
    url('../font/lato/lato-bold-webfont.ttf') format('truetype'),
    url('../font/lato/lato-bold-webfont.svg#lato-bold') format('svg');
    font-weight: normal;
    font-style: normal;

}



@font-face {
    font-family: 'lato-black';
    src: url('../font/lato/lato-black-webfont.eot');
    src: url('../font/lato/lato-black-webfont.eot?#iefix') format('embedded-opentype'),
    url('../font/lato/lato-black-webfont.woff') format('woff'),
    url('../font/lato/lato-black-webfont.ttf') format('truetype'),
    url('../font/lato/lato-black-webfont.svg#lato-black') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* ----------------------------------------------------- END FONT ----------------------------------------------------- */



html{
    font-size:62.5%;
}

body {
    font-family: aleo-light, sans-serif;
    color: #666;
    line-height: 1.45;
    font-weight: normal;
    width: 100%;
    font-size: 2.2rem;
}

header{
    position: fixed; top: 0; left: 0;
    -webkit-transition:-webkit-transform .3s ease .2s;
    -moz-transition:   -moz-transform .3s ease .2s;
    -ms-transition:   -moz-transform .3s ease .2s;
    -o-transition:     -o-transform .3s ease .2s;
    transition:        transform .3s ease .2s;
    width: 100%;
    height: 80px;
    margin: 0 auto;
    background: white;
    -webkit-box-shadow:inset 0 -6px 2px -6px #aaa;
    -moz-box-shadow:inset 0 -6px 2px -6px #aaa;
    -ms-box-shadow:inset 0 -6px 2px -6px #aaa;
    box-shadow:inset 0 -6px 2px -6px #aaa;
    z-index: 9999999;
}


.hideheader {
    -webkit-transform:translate(0, -100%);
    -moz-transform:translate(0, -100%);
    -ms-transform:translate(0, -100%);
    -o-transform:translate(0, -100%);
    transform:translate(0, -100%);

    -webkit-backface-visibility:hidden;
    -moz-backface-visibility:hidden;
    -ms-backface-visibility:hidden;
    -o-backface-visibility:hidden;
    backface-visibility:hidden;
}



#content-header{
    width: 85%;
    max-width: 1280px;
    margin: 0 auto;
}


main{
    width: 100%;
    background-color: white;
    display: block;
    margin: 0 auto;
    z-index: 999999;
}


a{
    text-decoration: none;
    border-bottom:1px solid $primary;
    padding-bottom: 1px;
    cursor: pointer;
    color: inherit;

}

a:hover{
    border-bottom: 1px dotted $primary;
    color: $primary;
}

a:visited, a:focus{
    color: #666;
}

.active{
    border-bottom: 1px solid $primary;
    color: $primary;
}



p {
    margin-bottom:3.1rem;
}



h1, h2, h3, h4{
    font-family: lato-light, sans-serif;
    margin: 0 0 2.2rem;
    font-weight: inherit;
    line-height: 1.2;
}

h1{
    text-transform: uppercase;
}

h2{
    font-size: 7.425rem;
    color: $secondary;
}

h3{
    font-family: lato-bold, sans-serif;
    font-size: 3.3rem;
    text-transform: uppercase;
    color: $secondary;
}

h4{
    font-family: lato-bold, sans-serif;
    font-size: 2.2rem;
}


.section-gris, .section-blanc{
    -webkit-box-shadow:inset 0 -6px 2px -6px #aaa;
    -moz-box-shadow:inset 0 -6px 2px -6px #aaa;
    box-shadow:inset 0 -6px 2px -6px #aaa;
}

/* BORDERBOX */
* {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;

    hyphens: none;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -o-hyphens: none;
    -ms-hyphens: none;
}
/* END BORDERBOX */


strong{
    font-weight: normal;
}

/*HTML5 pour IE*/
header, footer, article, section, nav, menu, hgroup, em {
 display: block;
}
/* END HTML5 pour IE*/




/* ----------------------------------------------------- BOUTON HEXAGONE ----------------------------------------------------- */

.button {
    font-family: lato-light;
    padding:10px 25px;
    display:inline-block;
    text-align: center;
    border:1px solid $primary;
    position: relative;
    height: 4.6rem;
    background: $primary;
    z-index:1;
    color:$snow;
    cursor:pointer;
    -webkit-transition:background .3s;
    -moz-transition:background .3s;
    -ms-transition:background .3s;
    -o-transition:background .3s;
    transition:background .3s;
    margin: 0 auto;
    // margin-left: 24px;
    // margin-top: 24px;
    text-transform: uppercase;
    font-size: 1.83335rem;
    &:visited, &:focus{
        color: $snow;
    }
}

// .button::before,
// .button::after {
//     content:"";
//     position: absolute;
//     top:1px;
//     bottom:0;
//     background:inherit;
//     border: 1px solid $primary;
//     width: 3.3rem;
//     height: 3.3rem;
//     -webkit-transform:rotate(45deg);
//     -moz-transform:rotate(45deg);
//     -ms-transform:rotate(45deg);
//     -o-transform:rotate(45deg);
//     transform:rotate(45deg);


//     z-index:-1;
// }

// .button::before {
//     left: -1px;
//     border-top: 0;
//     border-right: 0;
//     -webkit-transform-origin:2px 0;
//     -moz-transform-origin:2px 0;
//     -ms-transform-origin:2px 0;
//     -o-transform-origin:2px 0;
//     transform-origin:2px 0;
// }

// .button::after {
//     right:-5px;
//     border-bottom: 0;
//     border-left: 0;
//     -webkit-transform-origin:50% 100%;
//     -moz-transform-origin:50% 100%;
//     -ms-transform-origin:50% 100%;
//     -o-transform-origin:50% 100%;
//     transform-origin:50% 100%;
// }

.button:hover {
    background:$snow;
    border:1px solid $primary;
    color:$primary;
}

// .button-gris{
//     background: #fafafa;
// }

#bouton-home{
    margin-top: 20px;
}


/* ----------------------------------------------------- END BOUTON HEXAGONE ----------------------------------------------------- */





/* ----------------------------------------------------- BLOC DE TIERS ----------------------------------------------------- */
.tiers1{
    width: 32%;
}

.tiers2{
    width: 66%;
}

.tiers2-inter{
    width: 48.4%;
}
/* ----------------------------------------------------- END BLOC DE TIERS ----------------------------------------------------- */





/* ----------------------------------------------------- LOGO ----------------------------------------------------- */

#logo{

    margin: 0;
    position: absolute;
    top: 50%;
    margin-top: -25px;
    overflow: hidden;
    width: 251px;
    height: 50px;
    font-family:lato;
}

#logo svg{

    position: relative;
    z-index: 1000;
}


#logo a > span{

    position: absolute;
    left: 35px;
    overflow: hidden;
    text-indent: -251px;
    -webkit-transition:text-indent .6s ease-out;
    -moz-transition:text-indent .6s ease-out;
    -ms-transition:text-indent .6s ease-out;
    -o-transition:text-indent .6s ease-out;
    transition:text-indent .6s ease-out;
}

#logo:hover a > span{

    text-indent: 0px;
    padding-left: 24px;
}

#logo a{

    overflow: hidden;
    line-height: 50px;
    border-bottom: none;
    display: inline-block;
    position: relative;
    width: 100%;
    color: #666;
    font-size: 22px;
}

.color-span{
    color:$secondary;
}



#logo svg{
  -webkit-transform: rotate(0deg);
  -webkit-transition: .66s;
}

#logo svg:hover{
    -webkit-transform: rotate(360deg);
    -webkit-transition: .66s;
}


.txt_circle{
  animation: spinZ 70s linear infinite;
  transform-origin: center;
}


@keyframes spinZ {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
}

.st0{fill:#1D1D1B;}
.st1{fill:$primary;}
.st2{fill:none;stroke:#414140;stroke-width:10.3097;stroke-miterlimit:10;}
.st3{fill:none;stroke:#414140;stroke-width:18.5575;stroke-miterlimit:10;}
.st4{fill:#FFFFFF;}


/* ----------------------------------------------------- END LOGO ------------------------------------------------- */





/* ===================== NAVIGATION ============================================= */

nav{
    position: relative;
    font-family: lato-light, sans-serif;
    font-size: 1.4667rem;
}

nav ul {
    display: block;
    padding-left: 0;
    margin: 0;
}

nav ul li{
    display: inline-block;
    margin-left: 45px;
    line-height: 80px;
}

nav ul li:first-child{
    margin-left: 0;
}

nav ul li a{
    border: none;
    text-transform: uppercase;
}




.nav-active{
    border-bottom:1px solid $primary;
}
/* ===================== END NAVIGATION ============================================= */




/* ===================== SECTION INTRO ============================================= */

#intro .section-inter h2{
    margin-top: 97px;
    font-family:aleo-regular;
    color:#666;
    font-size: 3.3rem;
}

#intro2 .section-inter h2{
    margin-top: 128px;
    font-family:aleo-regular;
    color:#666;
    font-size: 3.3rem;
}


.titre-strong{
    font-size: 7.425rem;
    color: $secondary;
    font-family: lato-black;
    line-height: 1.2;
}

#intro div .titre-section-intro .titre-strong{
    text-transform: uppercase;
}

.logo_julien_watelet{
    margin-top: 97px;
    max-width: 200px;
    float: left;
    margin-right: 40px;
}



/* ===================== END SECTION INTRO ============================================= */




/* ===================== SECTION MOI ============================================= */
#calque-trace{
    position: fixed;
    right: 3.5%;
    bottom: 1px;
    width: 45%;
}

.photo-moi{
    position: fixed;
    right: 3.5%;
    bottom: 1px;
    width: 45%;
    max-width: 700px;
}


    /* ===================== effet avec waypoint sur photo de moi ============================================= */


    .no-js .photo-moi, .no-js #calque-trace{
        position: absolute;
    }

    .no-fixed .photo-moi, .no-fixed #calque-trace{
        position: absolute;
    }



    .chemin {
        stroke-dasharray: 2000;
        stroke-dashoffset: 2000;
        -webkit-animation:dash 3s linear alternate 2 forwards;
        -moz-animation:dash 3s linear alternate 2 forwards;
        -ms-animation:dash 3s linear alternate 2 forwards;
        -o-animation:dash 3s linear alternate 2 forwards;
        animation:dash 3s linear alternate 2 forwards;
    }

    @-webkit-keyframes dash {
      from {
        stroke-dashoffset: 2000;
    }
    to {
        stroke-dashoffset: 0;
    }
}


@-ms-keyframes dash {
  from {
    stroke-dashoffset: 2000;
}
to {
    stroke-dashoffset: 0;
}
}


@keyframes dash {
  from {
    stroke-dashoffset: 2000;
}
to {
    stroke-dashoffset: 0;
}
}

.photo-moi{
    opacity: 0;
    -webkit-animation:opacity-anim .5s linear 1 forwards;
    -moz-animation:opacity-anim .5s linear 1 forwards;
    -ms-animation:opacity-anim .5s linear 1 forwards;
    -o-animation:opacity-anim .5s linear 1 forwards;
    animation:opacity-anim .5s linear 1 forwards;

    -webkit-animation-delay:2.8s;
    -moz-animation-delay:2.8s;
    -o-animation-delay:2.8s;
    animation-delay:2.8s;
}

@-webkit-keyframes opacity-anim {
  from {
    opacity: 0;
}
to {
    opacity: 1;
}
}

@-ms-keyframes opacity-anim {
  from {
    opacity: 0;
}
to {
    opacity: 1;
}
}

@keyframes opacity-anim {
  from {
    opacity: 0;
}
to {
    opacity: 1;
}
}
    /* ===================== END effet avec waypoint sur photo de moi ============================================= */

/* ===================== END SECTION MOI ============================================= */



section{
    display: block;
    position: relative;
    overflow: hidden;
}

.section-gris{
    padding-bottom: 150px;
    padding-top: 150px;
    background-color: #fafafa;
}

.section-blanc{
    padding-bottom: 150px;
    padding-top: 150px;
}

.section-inter{
    margin: 0 auto;
    width: 85%;
    max-width: 1280px;
}



    /* ===================== LISTE DE TRAVAUX RECENT ============================================= */

    #travaux .section-inter div p a, #moi .section-inter div p a, #link-travaux .section-inter div a, #autres-travaux .section-inter div a {
        cursor: url("../img/externallink.png"), pointer;
    }

    #link-travaux .section-inter div .button{
        cursor: pointer;
    }

    #element-travaux{
        margin-top: -111px;
    }

    main #autres-travaux .section-inter li a{
        cursor: url("../img/externallink.png"), pointer;
    }

    main #autres-travaux .section-inter li:hover a{
        cursor: url("../img/externallink.png"), pointer;
    }






    #autres-travaux .section-inter li{
        float: left;
        display: block;
        // width: 32%;
        height: 100%;
        position: relative;
        overflow: hidden;
        border: 1px solid $secondary;
        margin-top:20px;
    }



    #autres-travaux .section-inter .li-centre{
        margin: 2% 2% 0 2%;
    }

    #autres-travaux .section-inter li:last-child{
        margin-right: 0;
    }

    main #autres-travaux .section-inter li a img{
        width: 100%;
        display: block;
        -webkit-transition:.5s;
        -moz-transition:.5s;
        -ms-transition:.5s;
        -o-transition:.5s;
        transition:.5s;
    }

    main #autres-travaux .section-inter li:hover a img{
        -webkit-transform:scale(1.2);
        -moz-transform:scale(1.2);
        -ms-transform:scale(1.2);
        -o-transform:scale(1.2);
        transform:scale(1.2);

    }


    main #autres-travaux .section-inter li div{
        position: absolute;
        top: 0%;
        left:0%;
        opacity:0;
        width: 100%;
        height: 100%;
        -webkit-transition:.5s;
        -moz-transition:.5s;
        -ms-transition:.5s;
        -o-transition:.5s;
        transition:.5s;

    }

    main #autres-travaux .section-inter li:hover div{
        background:rgba(255, 255, 255, 0.9);
        opacity:1;

    }

    #autres-travaux .titre-travaux-recent{
        text-align: center;
        padding: 5%;
        background: $secondary;
        color: white;
        margin-top: -25%;
        margin-bottom: 0;
        font-family: lato-light, sans-serif;
        text-transform: uppercase;
        -webkit-transition:.5s;
        -moz-transition:.5s;
        -ms-transition:.5s;
        -o-transition:.5s;
        transition:.5s;
        font-size: 2.2rem;
    }


    #autres-travaux li:hover .titre-travaux-recent{
      margin-top: -2px;
  }

  #autres-travaux .text-travaux-recent{
    margin-bottom: 0;
    margin-top: 120%;
    padding: 20% 0;
    -webkit-transition:0.5s;
    -moz-transition:0.5s;
    -ms-transition:0.5s;
    -o-transition:0.5s;
    transition:0.5s;
    text-align: center;
}

#autres-travaux .text-travaux-recent h4{

    font-family: lato-bold, sans-serif;
    text-transform: uppercase;
    color: #666;
    margin-bottom: 10px;
}

#autres-travaux .text-travaux-recent p{
    font-family: aleo-light, sans-serif;
    color: #666;
    font-size: 1.4667rem;
    font-style: italic;
}



#autres-travaux .text-travaux-recent h4::after {
    content: "";
    width: 120px;
    border-bottom: 1px solid $primary;
    display: block;
    margin: 12px auto 0;
}


#autres-travaux li:hover .text-travaux-recent{
  margin-top:35%;
  margin-top: -2px;
}






    /* ===================== END LISTE DE TRAVAUX RECENT ============================================= */



/* footer ---------------------------------------------------------------*/


footer{
    clear: both;
    height: 55px;
    background-color: white;
    font-size: 1.4667rem;
    font-family: lato-light;
}

footer a:hover {
    border-bottom: none;
}
.content-footer{
    width: 85%;
    max-width: 1280px;
    margin:0 auto;
    margin-top: 15px;
}

.content-footer ul{
    list-style-type:none;
    padding-left: 0!important;
}

footer ul li a{
    border: none;
}



.social-ul{
    margin: 0 auto;
    margin-top: 12px;
    float: right;
}

.social-ul li{
    display: inline-block;
    margin: 0 9px;
    font-family: lato-light, sans-serif;
    line-height:1.8rem;
}

.social-ul li.retour {
    margin: 0 0 0 9px;
}

.social-ul li.contact, .social-ul li.twitter, .social-ul li.googleplus, .social-ul li.linkedin, .social-ul li.behance{
    cursor: url("../img/externallink.png"), pointer;
}


.social-ul li.contact a {
  background: url('../img/sprite-social.png') no-repeat 0 0;
  width: 20px;
  height: 15px;
}
.social-ul li.twitter a {
  background: url('../img/sprite-social.png') no-repeat 25% 0;
  width: 19px;
  height: 15px;
}
.social-ul li.googleplus a {
  background: url('../img/sprite-social.png') no-repeat 49.686% 0;
  width: 16px;
  height: 15px;
}
.social-ul li.linkedin a {
  background: url('../img/sprite-social.png') no-repeat 73.75% 0;
  width: 15px;
  height: 15px;
}
.social-ul li.behance a {
  background: url('../img/sprite-social.png') no-repeat 100% 0;
  width: 23px;
  height: 15px;
}

.social-ul li.contact:hover a, .social-ul li.twitter:hover a, .social-ul li.googleplus:hover a, .social-ul li.linkedin:hover a, .social-ul li.behance:hover a{
    cursor: url("../img/externallink.png"), pointer;
}


.social-ul li.contact:hover a{
    background: url('../img/sprite-social.png') no-repeat 0 100%;
    width: 20px;
    height: 15px;
}

.social-ul li.twitter:hover a{
    background: url('../img/sprite-social.png') no-repeat 25% 100%;
    width: 19px;
    height: 15px;
}

.social-ul li.googleplus:hover a{
    background: url('../img/sprite-social.png') no-repeat 49.686% 100%;
    width: 16px;
    height: 15px;
}

.social-ul li.linkedin:hover a{
    background: url('../img/sprite-social.png') no-repeat 73.75% 100%;
    width: 15px;
    height: 15px;
}

.social-ul li.behance:hover a{
    background: url('../img/sprite-social.png') no-repeat 100% 100%;
    width: 23px;
    height: 15px;
}

.social-ul li.contact a, .social-ul li.twitter a, .social-ul li.googleplus a, .social-ul li.linkedin a, .social-ul li.behance a{
    display:inline-block;
    text-indent: -9999px;

}




/* end footer ---------------------------------------------------------------*/




/* ================ Page contact ======================================================== */

#contact .section-inter h2{
    font-family:aleo-regular;
    color:#666;
    font-size: 3.3rem;
}

.maps{
    margin-top: 85px;
    background: url(../img/map.jpg);
    background-position-x: 70%;
    -webkit-background-size: cover;          
    -moz-background-size: cover; 
    -ms-background-size: cover;          
    -o-background-size: cover;           
    background-size: cover;  
    font-size: 1.4667rem;
    height: 395px;
    position: relative;
}

.no-js .maps{
    border: 1px solid $secondary;
}


.coordonnee{
    text-align: center;
    list-style: none;
    padding: 5px 25px 25px 25px;
    border: 1px solid $secondary;
    background: rgba(255,255,255,0.9);
    display: block;
    float: right;
    margin-right: 80px;
    margin-top: 80px;
    position: absolute;
    z-index: 9999;
    right: 0;
}

.coordonnee li {
    margin-top: 20px;
}

.coordonnee li a {
    border: none;
    font-family: aleo-bold;
}


#map-canvas{
    height: 395px;
    border: 1px solid $secondary;
}



    /* ================ Formulaire ======================================================== */


    .cont-form{
        font-family: lato-light;
    }



    input {
        border: 1px solid $secondary!important;
        padding: 10px!important;
        display: initial!important;
        height: auto!important;
        outline: 0;
        color: black;
        font-size: 1.4667rem!important;
        width: 100%!important;
        margin: 15px auto!important;
        font-family: lato-light!important;
        transition: all 0.1s ease-in-out;
    }

    input:focus {
        border: 2px solid $secondary!important;
        font-family: lato-bold!important;
        padding: 10px;
    }
    .active-floatlabel{
        padding-top: 22px!important;
    }
    textarea {
        height: 100px;
        width: 100%;
        border: 1px solid $secondary;
        padding: 10px;
        outline: 0;
        color:black ;
        font-size: 1.4667rem!important;
        font-family: lato-light;
        margin: 15px auto;
    }


    textarea:focus {
        border: 2px solid $secondary;
        font-family: lato-bold;
    }


    #button, input[type="submit"] {
        color:white;
        background:$primary;
        -webkit-transition:all .3s;
        -moz-transition:all .3s;
        -ms-transition:all .3s;
        -o-transition:all .3s;
        transition:all .3s;
        cursor: pointer;
        font-family: lato-light;
        text-transform: uppercase;
        border: solid $primary;
    }

    #button:focus, input[type="submit"] {
        border: 1px solid $primary;
        -webkit-animation-name:bounce;
        -moz-animation-name:bounce;
        -ms-animation-name:bounce;
        -o-animation-name:bounce;
        animation-name:bounce;

        -webkit-animation-duration:.8s;
        -moz-animation-duration:.8s;
        -ms-animation-duration:.8s;
        -o-animation-duration:.8s;
        animation-duration:.8s;

        -webkit-animation-iteration-count: 1;
        -moz-animation-iteration-count: 1;
        -ms-animation-iteration-count: 1;
        -o-animation-iteration-count: 1;
        animation-iteration-count: 1;

        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }

    @-webkit-keyframes bounce {
        0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}
        40% {-webkit-transform: translateY(-10px);}
        60% {-webkit-transform: translateY(-4px);}
    }

    @-ms-keyframes bounce {
        0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}
        40% {-webkit-transform: translateY(-10px);}
        60% {-webkit-transform: translateY(-4px);}
    }

    @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}
        40% {-webkit-transform: translateY(-10px);}
        60% {-webkit-transform: translateY(-4px);}
    }

    #button:hover {
        background: white;
        color: $primary;
        cursor: pointer;
    }

    input[type="submit"]{
            border: 1px solid $primary !important;
    }
    input[type="submit"]:hover{
        background: white;
        color: $primary;
        cursor: pointer;
            border: 1px solid $primary !important;
    }

    .label-floatlabel {
        font-weight: bold!important;
        font-size: 11px!important;
        margin-top: 13px!important;
    }

    label.red-label {
        color: red !important; /*obligatoire pour le label*/
    }

    .floatlabel_form{
        input[type="submit"]{
            margin-top: 0!important;
        }
    }

    #error {
        font-family: lato-light;
        font-weight: 300;
        color: #ff7F4E;
    }

    #error p{
        margin-bottom: 0;
    }

    #error ul li {
        list-style: none;
    }

    .errors-display {
        width: 654px;
        margin: 0 auto;
        height: 60px;
        font-size: 1.4667rem;
        margin-top: 10px;
        color: #ff7F4E;
    }

    .errors-display p {
        display: none;
        margin-bottom: 0;
    }

    #contact h2{
        margin-bottom: 0;
    }

    textarea{
        max-width: 100%;
        max-height: 800px;
    }

    form label{
        display: none;
        color: $secondary;
    }

    .no-js form label{
        display: block;
        margin-bottom: 0;
    }

    .no-js input{
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .no-js #message{
        margin-top: 5px;
        margin-bottom: 5px;
    }


    .no-js input::-webkit-input-placeholder { color: white; }
    .no-js input:-moz-placeholder { color: white; }
    .no-js input::-moz-placeholder { color: white; }
    .no-js input:-ms-placeholder { color: white; }






    /* ================ Fin Formulaire ======================================================== */



/* ================ Fin page contact ======================================================== */




.clearfix:after {
   content: ".";
   display: block;
   clear: both;
   visibility: hidden;
   line-height: 0;
   height: 0;
}
.clearfix {display: block;}
html[xmlns] .clearfix {display: block;}
* html .clearfix {height: 1%;}

.clear{
    clear: both;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.float-right-menu {
    float: right;
}

.float-none {
    float: none;
}

.bottom{
    padding-bottom: 0;
}



/* ================ Page DATAVIZ ======================================================== */



.acces-site{
    text-align: center;
}

.acces-site a{
    cursor: url("../img/externallink.png"), pointer;
    margin-left: 0;
}

.acces-site a:nth-child(3){
    margin-right: 74px;
}

.acces-site a:nth-child(4){
    cursor: pointer;
}

.acces-site h3 {
    font-family: lato-bold;
}

.acces-site p {
    width: 75%;
    margin: 0 auto 3.1rem auto;
}

#element-dataviz{
    position: absolute;
    bottom: 0;
    width: 40%;
    right: 7%;
    max-width: 610px;
}

.dataviz img{
    margin-top: 9px;
}



/* ================ Fin Page DATAVIZ ======================================================== */






/* ================ Page 404 ======================================================== */



.section-photo-404{
    padding: 0;
    margin: 0;
    font-size: 0;
    line-height: 0;
}

.section-photo-404 img{
    width: 50%;
}

cite{
    display: block;
    padding-bottom: 25px;
}

q{
    padding-top: 152px;
    display: block;
}



/* ================ Fin Page 404 ======================================================== */



/* ================ Page credits ======================================================== */



.credits li{
    list-style: none;
}

.credits li a{
    cursor: url("../img/externallink.png"), pointer;
}

.credits .tiers1 {
    margin-right: 2%;
}

.credits .tiers1:last-child {
    margin-right: 0;
}

.credits .button{
    margin-top: 100px;
}


/* ================ Fin Page credits ======================================================== */


/* ================ Page politique ======================================================== */





.credits .tiers2-inter {
    margin-right: 2%;
}

.credits .tiers2-inter:last-child {
    margin-right: 0;
}



/* ================ Fin Page politique ======================================================== */





/* MEDIA QUERIES ============================================================================== */

@media screen and (max-width: 1350px) {


    #link-travaux a{
        margin-bottom: 150px;
    }

}


@media screen and (max-width: 1050px) {

    #autres-travaux .section-inter .li-centre {
        margin: 2% 0 0 0;
    }


    #autres-travaux .section-inter li {
        // width: 49%;
        margin-top: 2%;
    }

    #autres-travaux .section-inter li:nth-child(2n) {
        margin-left: 2%;
    }

    #link-travaux a{
        margin-bottom: 150px;
    }

}


@media screen and (max-width: 900px) {





    .credits .tiers1, .credits .tiers2-inter {
        float: none;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 62px;
        width: 100%;
    }

    .credits .tiers2-inter {
        float: none;
        text-align: left;
        margin: 0 auto;
        margin-bottom: 62px;
        width: 100%;
    }

    .credits .tiers1:last-child, .credits .tiers2-inter:last-child {
        margin-right: auto;
        margin-bottom: 0;
    }

    .credits .button{
        margin-left: 0;
    }

    #intro .section-inter h2{
        margin-top: 36px;
    }

    #intro2 .section-inter h2{
        margin-top: 36px;
    }

    #map-canvas{
        display: none;
    }

    .maps{
        border: 1px solid $secondary;
    }

    #element-dataviz {
        width: 30%;
    }

}

@media screen and (max-width: 850px) {

    header{
        height: 130px;
        position: relative;
    }

    .hideheader {
        -webkit-transform: translate(0, 0%);
        -moz-transform: translate(0, 0%);
        -ms-transform: translate(0, 0%);
        -o-transform: translate(0, 0%);
        transform: translate(0, 0%);
        -webkit-backface-visibility: visible;
        -moz-backface-visibility: visible;
        -ms-backface-visibility: visible;
        -o-backface-visibility: visible;
        backface-visibility: visible;
    }

    #logo {
        margin-top: 25px;
        left: 50%;
        margin-left: -25px;
        position: relative;
        height: 100%;
    }

    #logo a {
        line-height: 0;
    }


    .float-right-menu {
        float: none;
        text-align: center;
    }

    nav ul li {
        margin-left: 25px;
        line-height: 50px;
    }



    footer{
        text-align: center;
        height: 130px;
    }

    .social-ul{
        float: none;
        margin-top: 34px;
        margin-bottom: 20px;
    }

    .social-ul::after{
        content: "";
        width: 120px;
        border-bottom:1px dotted $primary;
        display: block;
        margin: 18px auto 0;
    }


    .tiers1 {
       width: 100%;
   }

   .tiers2 {
    width: 100%;
}

.tiers2-inter {
    width: 100%;
}

#link-travaux .button{
    margin-bottom: 60px;
}

#calque-trace{
    display: none;
}

.photo-moi{
    position: absolute;
}

#calque-trace{
    width: 35%;
}

.photo-moi{
    width: 35%;
}

.active{
    border-bottom: none;
    color: #666;
}

}


@media screen and (max-width: 800px) {

    #autres-travaux .section-inter .li-centre {
        margin: 80px 0 0 0;
    }
    #autres-travaux .section-inter li {
        width: 100%;
        margin-top: 80px;
        overflow: visible;
    }

    #autres-travaux .section-inter li:nth-child(2n) {
        margin-left: 0;
    }




    main #autres-travaux .section-inter li div{
        position: absolute;
        top: 0%;
        left:0%;
        opacity:1;
        width: 100%;
        height: 32px;
        -webkit-transition:.5s;
        -moz-transition:.5s;
        -ms-transition:.5s;
        -o-transition:.5s;
        transition:.5s;

    }


    main #autres-travaux .section-inter li:hover a img{
        -webkit-transform:scale(1);
        -moz-transform:scale(1);
        -ms-transform:scale(1);
        -o-transform:scale(1);
        transform:scale(1);

    }


    main #autres-travaux .section-inter li div{

        -webkit-transition:0;
        -moz-transition:0;
        -ms-transition:0;
        -o-transition:0;
        transition:0;
        top: 105%;

    }

    main #autres-travaux .section-inter li:hover div{
        background:none;
        opacity:1;

    }

    #autres-travaux .titre-travaux-recent{
        text-align: center;
        padding: 5px;
        background: none;
        color: #666;
        margin-top: 0;
        margin-bottom: 0;
        font-family: lato-light, sans-serif;
        text-transform: uppercase;
        -webkit-transition:.5s;
        -moz-transition:.5s;
        -ms-transition:.5s;
        -o-transition:.5s;
        transition:.5s;
        font-size: 2.2rem;
    }


    #autres-travaux li:hover .titre-travaux-recent{
      margin-top: 0px;
  }

  #autres-travaux .text-travaux-recent{
    display: none;
}

}



@media screen and (max-width: 650px) {
    .logo_julien_watelet{
        margin-top: 20px;
    }

    .float-left{
        float: none;
    }

    #intro .section-inter h2{
        margin-top: 20px;
    }

    #intro2 .section-inter h2{
        margin-top: 0;
    }

    .section-gris, .section-blanc{
        padding-top: 75px;
        padding-bottom: 75px;
    }

    .bottom{
        padding-bottom: 0;
    }

    #element-travaux {
        margin-top: 0;
    }


    .maps {
        margin-top: 47px;
    }

    #element-dataviz {
        width: 20%;
    }

    .section-photo-404 img {
        width: 100%;
    }





}

@media screen and (max-width: 560px) {
    .logo_julien_watelet{
        margin-top: 20px;
    }
    
    .acces-site a:nth-child(3){
        margin-right: 0;
        margin-bottom: 23px;
    }

    .acces-site a{
        width: 100%;
    }
}

@media screen and (max-width: 450px) {
    .block_section_intro{
        text-align: center;
    }
    .block_logo{
        width: 100%;
        display: block;
        position: relative;
    }
    .logo_julien_watelet{
        margin: 0 auto;
        text-align: center;
        display: block;
        width: 100%;
        position: relative;
    // display: flex;  
    // justify-content: center;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.maps {
    height: 325px;
}


header{
    height: 230px;
    z-index: 0;
}

nav ul li {
    display: block;
    margin-left: 0;
    margin-bottom: 0;
    line-height: 37px;
}


.float-left{
    float: none;
}

#bouton-moi{
    margin-bottom: 35px;
}



.coordonnee{
    margin-right: 0;
    margin-top: 0;
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 0;
}

.coordonnee li {
    margin-top: 46px;
}

h2{
    font-size: 4.95rem;
}

.titre-strong{
    font-size: 4.95rem;
}

footer {
    height: 170px;
}

}

/*Solution pour une css internet explorer au dessus de la version 9 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
 .photo-moi{
    opacity: 1;
    -webkit-animation:opacity-anim 1s linear 1 forwards;
    -moz-animation:opacity-anim 1s linear 1 forwards;
    -ms-animation:opacity-anim 1s linear 1 forwards;
    -o-animation:opacity-anim 1s linear 1 forwards;
    animation:opacity-anim 1s linear 1 forwards;

    -webkit-animation-delay:0;
    -moz-animation-delay:0;
    -o-animation-delay:0;
    animation-delay:0;
} 
}




/* END MEDIA QUERIES ============================================================================== */




@include breakpoint(small down) {

}
