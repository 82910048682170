@charset "UTF-8";
@-webkit-keyframes heartbeat {
  0% {
    -moz-transform: rotate(45deg) scale(1);
    -o-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1); }
  14% {
    -moz-transform: rotate(45deg) scale(1.3);
    -o-transform: rotate(45deg) scale(1.3);
    -ms-transform: rotate(45deg) scale(1.3);
    -webkit-transform: rotate(45deg) scale(1.3);
    transform: rotate(45deg) scale(1.3); }
  28% {
    -moz-transform: rotate(45deg) scale(1);
    -o-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1); }
  42% {
    -moz-transform: rotate(45deg) scale(1.3);
    -o-transform: rotate(45deg) scale(1.3);
    -ms-transform: rotate(45deg) scale(1.3);
    -webkit-transform: rotate(45deg) scale(1.3);
    transform: rotate(45deg) scale(1.3); }
  70% {
    -moz-transform: rotate(45deg) scale(1);
    -o-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1); } }

@-moz-keyframes heartbeat {
  0% {
    -moz-transform: rotate(45deg) scale(1);
    -o-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1); }
  14% {
    -moz-transform: rotate(45deg) scale(1.3);
    -o-transform: rotate(45deg) scale(1.3);
    -ms-transform: rotate(45deg) scale(1.3);
    -webkit-transform: rotate(45deg) scale(1.3);
    transform: rotate(45deg) scale(1.3); }
  28% {
    -moz-transform: rotate(45deg) scale(1);
    -o-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1); }
  42% {
    -moz-transform: rotate(45deg) scale(1.3);
    -o-transform: rotate(45deg) scale(1.3);
    -ms-transform: rotate(45deg) scale(1.3);
    -webkit-transform: rotate(45deg) scale(1.3);
    transform: rotate(45deg) scale(1.3); }
  70% {
    -moz-transform: rotate(45deg) scale(1);
    -o-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1); } }

@-o-keyframes heartbeat {
  0% {
    -moz-transform: rotate(45deg) scale(1);
    -o-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1); }
  14% {
    -moz-transform: rotate(45deg) scale(1.3);
    -o-transform: rotate(45deg) scale(1.3);
    -ms-transform: rotate(45deg) scale(1.3);
    -webkit-transform: rotate(45deg) scale(1.3);
    transform: rotate(45deg) scale(1.3); }
  28% {
    -moz-transform: rotate(45deg) scale(1);
    -o-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1); }
  42% {
    -moz-transform: rotate(45deg) scale(1.3);
    -o-transform: rotate(45deg) scale(1.3);
    -ms-transform: rotate(45deg) scale(1.3);
    -webkit-transform: rotate(45deg) scale(1.3);
    transform: rotate(45deg) scale(1.3); }
  70% {
    -moz-transform: rotate(45deg) scale(1);
    -o-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1); } }

@keyframes heartbeat {
  0% {
    -moz-transform: rotate(45deg) scale(1);
    -o-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1); }
  14% {
    -moz-transform: rotate(45deg) scale(1.3);
    -o-transform: rotate(45deg) scale(1.3);
    -ms-transform: rotate(45deg) scale(1.3);
    -webkit-transform: rotate(45deg) scale(1.3);
    transform: rotate(45deg) scale(1.3); }
  28% {
    -moz-transform: rotate(45deg) scale(1);
    -o-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1); }
  42% {
    -moz-transform: rotate(45deg) scale(1.3);
    -o-transform: rotate(45deg) scale(1.3);
    -ms-transform: rotate(45deg) scale(1.3);
    -webkit-transform: rotate(45deg) scale(1.3);
    transform: rotate(45deg) scale(1.3); }
  70% {
    -moz-transform: rotate(45deg) scale(1);
    -o-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1); } }

/* Styles for old versions of IE */
.heartbeat {
  font-family: sans-serif;
  font-weight: 100;
  margin-left: 5px;
  margin-right: 5px; }

/* :not(:required) hides this rule from IE9 and below */
.heartbeat:not(:required) {
  -webkit-animation: heartbeat 2300ms ease 0s infinite normal;
  -khtml-animation: heartbeat 2300ms ease 0s infinite normal;
  -moz-animation: heartbeat 2300ms ease 0s infinite normal;
  -ms-animation: heartbeat 2300ms ease 0s infinite normal;
  -o-animation: heartbeat 2300ms ease 0s infinite normal;
  animation: heartbeat 2300ms ease 0s infinite normal;
  display: inline-block;
  position: relative;
  overflow: hidden;
  text-indent: -9999px;
  width: 10px;
  height: 10px;
  -moz-transform: rotate(45deg) scale(1);
  -o-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  -webkit-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  -moz-transform-origin: 50%;
  -o-transform-origin: 50%;
  -ms-transform-origin: 50%;
  -webkit-transform-origin: 50%;
  transform-origin: 50%; }
  .heartbeat:not(:required):after, .heartbeat:not(:required):before {
    position: absolute;
    content: "";
    background: #636363; }
  .heartbeat:not(:required):before {
    -webkit-border-left-radius: 3.33333px;
    -moz-border-left-radius: 3.33333px;
    -ms-border-left-radius: 3.33333px;
    border-left-radius: 3.33333px;
    top: 3.33333px;
    left: 0;
    width: 10px;
    height: 6.66667px; }
  .heartbeat:not(:required):after {
    -webkit-border-top-radius: 3.33333px;
    -moz-border-top-radius: 3.33333px;
    -ms-border-top-radius: 3.33333px;
    border-top-radius: 3.33333px;
    top: 0;
    left: 3.33333px;
    width: 6.66667px;
    height: 3.33333px; }

/*
  Margins

  provides utility class such as:

    .mb5  : margin-bottom 5px
    .mt15 : margin-top 15px
    etc.

*/
.margin0 {
  margin: 0px; }

.padding0 {
  padding: 0px; }

.mb0 {
  margin-bottom: 0px !important; }

.mt0 {
  margin-top: 0px !important; }

.mr0 {
  margin-right: 0px !important; }
  .mr0:hover {
    margin-right: 0px !important; }

.ml0 {
  margin-left: 0px !important; }

.pl0 {
  padding-left: 0px !important; }

.pr0 {
  padding-right: 0px !important; }

.pt0 {
  padding-top: 0px !important; }

.pb0 {
  padding-bottom: 0px !important; }

.mb5 {
  margin-bottom: 5px !important; }

.mt5 {
  margin-top: 5px !important; }

.mr5 {
  margin-right: 5px !important; }
  .mr5:hover {
    margin-right: 5px !important; }

.ml5 {
  margin-left: 5px !important; }

.pl5 {
  padding-left: 5px !important; }

.pr5 {
  padding-right: 5px !important; }

.pt5 {
  padding-top: 5px !important; }

.pb5 {
  padding-bottom: 5px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.mt10 {
  margin-top: 10px !important; }

.mr10 {
  margin-right: 10px !important; }
  .mr10:hover {
    margin-right: 10px !important; }

.ml10 {
  margin-left: 10px !important; }

.pl10 {
  padding-left: 10px !important; }

.pr10 {
  padding-right: 10px !important; }

.pt10 {
  padding-top: 10px !important; }

.pb10 {
  padding-bottom: 10px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.mt15 {
  margin-top: 15px !important; }

.mr15 {
  margin-right: 15px !important; }
  .mr15:hover {
    margin-right: 15px !important; }

.ml15 {
  margin-left: 15px !important; }

.pl15 {
  padding-left: 15px !important; }

.pr15 {
  padding-right: 15px !important; }

.pt15 {
  padding-top: 15px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.mt20 {
  margin-top: 20px !important; }

.mr20 {
  margin-right: 20px !important; }
  .mr20:hover {
    margin-right: 20px !important; }

.ml20 {
  margin-left: 20px !important; }

.pl20 {
  padding-left: 20px !important; }

.pr20 {
  padding-right: 20px !important; }

.pt20 {
  padding-top: 20px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.mb25 {
  margin-bottom: 25px !important; }

.mt25 {
  margin-top: 25px !important; }

.mr25 {
  margin-right: 25px !important; }
  .mr25:hover {
    margin-right: 25px !important; }

.ml25 {
  margin-left: 25px !important; }

.pl25 {
  padding-left: 25px !important; }

.pr25 {
  padding-right: 25px !important; }

.pt25 {
  padding-top: 25px !important; }

.pb25 {
  padding-bottom: 25px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.mt30 {
  margin-top: 30px !important; }

.mr30 {
  margin-right: 30px !important; }
  .mr30:hover {
    margin-right: 30px !important; }

.ml30 {
  margin-left: 30px !important; }

.pl30 {
  padding-left: 30px !important; }

.pr30 {
  padding-right: 30px !important; }

.pt30 {
  padding-top: 30px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.mb35 {
  margin-bottom: 35px !important; }

.mt35 {
  margin-top: 35px !important; }

.mr35 {
  margin-right: 35px !important; }
  .mr35:hover {
    margin-right: 35px !important; }

.ml35 {
  margin-left: 35px !important; }

.pl35 {
  padding-left: 35px !important; }

.pr35 {
  padding-right: 35px !important; }

.pt35 {
  padding-top: 35px !important; }

.pb35 {
  padding-bottom: 35px !important; }

.mb40 {
  margin-bottom: 40px !important; }

.mt40 {
  margin-top: 40px !important; }

.mr40 {
  margin-right: 40px !important; }
  .mr40:hover {
    margin-right: 40px !important; }

.ml40 {
  margin-left: 40px !important; }

.pl40 {
  padding-left: 40px !important; }

.pr40 {
  padding-right: 40px !important; }

.pt40 {
  padding-top: 40px !important; }

.pb40 {
  padding-bottom: 40px !important; }

.mb45 {
  margin-bottom: 45px !important; }

.mt45 {
  margin-top: 45px !important; }

.mr45 {
  margin-right: 45px !important; }
  .mr45:hover {
    margin-right: 45px !important; }

.ml45 {
  margin-left: 45px !important; }

.pl45 {
  padding-left: 45px !important; }

.pr45 {
  padding-right: 45px !important; }

.pt45 {
  padding-top: 45px !important; }

.pb45 {
  padding-bottom: 45px !important; }

.mb50 {
  margin-bottom: 50px !important; }

.mt50 {
  margin-top: 50px !important; }

.mr50 {
  margin-right: 50px !important; }
  .mr50:hover {
    margin-right: 50px !important; }

.ml50 {
  margin-left: 50px !important; }

.pl50 {
  padding-left: 50px !important; }

.pr50 {
  padding-right: 50px !important; }

.pt50 {
  padding-top: 50px !important; }

.pb50 {
  padding-bottom: 50px !important; }

.mb55 {
  margin-bottom: 55px !important; }

.mt55 {
  margin-top: 55px !important; }

.mr55 {
  margin-right: 55px !important; }
  .mr55:hover {
    margin-right: 55px !important; }

.ml55 {
  margin-left: 55px !important; }

.pl55 {
  padding-left: 55px !important; }

.pr55 {
  padding-right: 55px !important; }

.pt55 {
  padding-top: 55px !important; }

.pb55 {
  padding-bottom: 55px !important; }

.mb60 {
  margin-bottom: 60px !important; }

.mt60 {
  margin-top: 60px !important; }

.mr60 {
  margin-right: 60px !important; }
  .mr60:hover {
    margin-right: 60px !important; }

.ml60 {
  margin-left: 60px !important; }

.pl60 {
  padding-left: 60px !important; }

.pr60 {
  padding-right: 60px !important; }

.pt60 {
  padding-top: 60px !important; }

.pb60 {
  padding-bottom: 60px !important; }

.mb65 {
  margin-bottom: 65px !important; }

.mt65 {
  margin-top: 65px !important; }

.mr65 {
  margin-right: 65px !important; }
  .mr65:hover {
    margin-right: 65px !important; }

.ml65 {
  margin-left: 65px !important; }

.pl65 {
  padding-left: 65px !important; }

.pr65 {
  padding-right: 65px !important; }

.pt65 {
  padding-top: 65px !important; }

.pb65 {
  padding-bottom: 65px !important; }

.mb70 {
  margin-bottom: 70px !important; }

.mt70 {
  margin-top: 70px !important; }

.mr70 {
  margin-right: 70px !important; }
  .mr70:hover {
    margin-right: 70px !important; }

.ml70 {
  margin-left: 70px !important; }

.pl70 {
  padding-left: 70px !important; }

.pr70 {
  padding-right: 70px !important; }

.pt70 {
  padding-top: 70px !important; }

.pb70 {
  padding-bottom: 70px !important; }

.mb75 {
  margin-bottom: 75px !important; }

.mt75 {
  margin-top: 75px !important; }

.mr75 {
  margin-right: 75px !important; }
  .mr75:hover {
    margin-right: 75px !important; }

.ml75 {
  margin-left: 75px !important; }

.pl75 {
  padding-left: 75px !important; }

.pr75 {
  padding-right: 75px !important; }

.pt75 {
  padding-top: 75px !important; }

.pb75 {
  padding-bottom: 75px !important; }

.mb80 {
  margin-bottom: 80px !important; }

.mt80 {
  margin-top: 80px !important; }

.mr80 {
  margin-right: 80px !important; }
  .mr80:hover {
    margin-right: 80px !important; }

.ml80 {
  margin-left: 80px !important; }

.pl80 {
  padding-left: 80px !important; }

.pr80 {
  padding-right: 80px !important; }

.pt80 {
  padding-top: 80px !important; }

.pb80 {
  padding-bottom: 80px !important; }

.mb85 {
  margin-bottom: 85px !important; }

.mt85 {
  margin-top: 85px !important; }

.mr85 {
  margin-right: 85px !important; }
  .mr85:hover {
    margin-right: 85px !important; }

.ml85 {
  margin-left: 85px !important; }

.pl85 {
  padding-left: 85px !important; }

.pr85 {
  padding-right: 85px !important; }

.pt85 {
  padding-top: 85px !important; }

.pb85 {
  padding-bottom: 85px !important; }

.mb90 {
  margin-bottom: 90px !important; }

.mt90 {
  margin-top: 90px !important; }

.mr90 {
  margin-right: 90px !important; }
  .mr90:hover {
    margin-right: 90px !important; }

.ml90 {
  margin-left: 90px !important; }

.pl90 {
  padding-left: 90px !important; }

.pr90 {
  padding-right: 90px !important; }

.pt90 {
  padding-top: 90px !important; }

.pb90 {
  padding-bottom: 90px !important; }

.mb95 {
  margin-bottom: 95px !important; }

.mt95 {
  margin-top: 95px !important; }

.mr95 {
  margin-right: 95px !important; }
  .mr95:hover {
    margin-right: 95px !important; }

.ml95 {
  margin-left: 95px !important; }

.pl95 {
  padding-left: 95px !important; }

.pr95 {
  padding-right: 95px !important; }

.pt95 {
  padding-top: 95px !important; }

.pb95 {
  padding-bottom: 95px !important; }

.mb100 {
  margin-bottom: 100px !important; }

.mt100 {
  margin-top: 100px !important; }

.mr100 {
  margin-right: 100px !important; }
  .mr100:hover {
    margin-right: 100px !important; }

.ml100 {
  margin-left: 100px !important; }

.pl100 {
  padding-left: 100px !important; }

.pr100 {
  padding-right: 100px !important; }

.pt100 {
  padding-top: 100px !important; }

.pb100 {
  padding-bottom: 100px !important; }

/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*BURGER*/
* {
  box-sizing: border-box; }

.material--burger {
  cursor: pointer;
  padding: 12.5px;
  position: relative;
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.8); }
  .material--burger:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #ddbb0b;
    animation: material-menu-background 300ms ease-in-out forwards; }
  .material--burger span {
    top: 50%;
    transform-origin: center center;
    animation-name: material-menu; }
    .material--burger span, .material--burger span:before, .material--burger span:after {
      width: 25px;
      background-color: #fff;
      height: 3px;
      position: absolute;
      z-index: 525;
      animation-duration: 300ms;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
      transition: background-color 300ms ease-in-out; }
    .material--burger span:before, .material--burger span:after {
      content: '';
      display: block; }
    .material--burger span:before {
      top: 300%;
      transform-origin: right bottom;
      animation-name: material-menu-before; }
    .material--burger span:after {
      bottom: 300%;
      transform-origin: right top;
      animation-name: material-menu-after; }
  .material--burger.material--arrow:after {
    animation: material-arrow-background 300ms ease-in-out forwards; }
  .material--burger.material--arrow span,
  .material--burger.material--arrow span:before,
  .material--burger.material--arrow span:after {
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    background-color: #ddbb0b; }
  .material--burger.material--arrow span {
    animation-name: material-arrow; }
  .material--burger.material--arrow span:before {
    animation-name: material-arrow-before; }
  .material--burger.material--arrow span:after {
    animation-name: material-arrow-after; }

@keyframes material-arrow-background {
  100% {
    transform: rotate(180deg) scale(0); } }

@keyframes material-menu-background {
  0% {
    transform: rotate(180deg) scale(0); }
  100% {
    transform: rotate(360deg) scale(1); } }

@keyframes material-arrow {
  100% {
    transform: rotate(270deg); } }

@keyframes material-arrow-before {
  100% {
    top: 0;
    transform: rotate(-40deg) scaleX(0.7); } }

@keyframes material-arrow-after {
  100% {
    bottom: 0;
    transform: rotate(40deg) scaleX(0.7); } }

@keyframes material-menu {
  0% {
    transform: rotate(180deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes material-menu-before {
  0% {
    top: 0;
    transform: rotate(-40deg) scaleX(0.7); }
  100% {
    top: 300%;
    transform: rotate(0) scaleX(1); } }

@keyframes material-menu-after {
  0% {
    bottom: 0;
    transform: rotate(40deg) scaleX(0.7); }
  100% {
    bottom: 300%;
    transform: rotate(0) scaleX(1); } }

.material--burger {
  /* margin: 90px auto;*/ }

.navigation--button {
  width: auto;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 50px;
  top: 20px;
  display: none;
  z-index: 9999; }

/*BURGER*/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./../img/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/../../font/slick.eot");
  src: url("./fonts/../../font/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/../../font/slick.woff") format("woff"), url("./fonts/../../font/slick.ttf") format("truetype"), url("./fonts/../../font/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' '; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 1.25rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 1.875rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

.accordion {
  margin-left: 0;
  background: #fefefe;
  list-style-type: none; }

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #1779ba; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #e6e6e6;
    border-radius: 0 0 0 0; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #e6e6e6; }
  .accordion-title::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.5rem;
    content: '+'; }
  .is-active > .accordion-title::before {
    content: '\2013'; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fefefe;
  color: #0a0a0a; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #e6e6e6; }

.is-accordion-submenu-parent > a {
  position: relative; }
  .is-accordion-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1rem; }

.is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%; }

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #1779ba;
  color: #fefefe; }
  .badge.primary {
    background: #1779ba;
    color: #fefefe; }
  .badge.secondary {
    background: #767676;
    color: #fefefe; }
  .badge.success {
    background: #3adb76;
    color: #0a0a0a; }
  .badge.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .badge.alert {
    background: #cc4b37;
    color: #fefefe; }

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' '; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 0.6875rem;
    color: #0a0a0a;
    cursor: default;
    text-transform: uppercase; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      top: 1px;
      margin: 0 0.75rem;
      opacity: 1;
      content: "/";
      color: #cacaca; }
  .breadcrumbs a {
    color: #1779ba; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #cacaca;
    cursor: not-allowed; }

.button-group {
  margin-bottom: 1rem;
  font-size: 0; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' '; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded {
    margin-right: -1px; }
    .button-group.expanded::before, .button-group.expanded::after {
      display: none; }
    .button-group.expanded .button:first-child:last-child {
      width: 100%; }
    .button-group.expanded .button:first-child:nth-last-child(2), .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button {
      display: inline-block;
      width: calc(50% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(2):last-child, .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(3), .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button {
      display: inline-block;
      width: calc(33.33333% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(3):last-child, .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(4), .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button {
      display: inline-block;
      width: calc(25% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(4):last-child, .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(5), .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button {
      display: inline-block;
      width: calc(20% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(5):last-child, .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(6), .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button {
      display: inline-block;
      width: calc(16.66667% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(6):last-child, .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button:last-child {
        margin-right: -6px; }
  .button-group.primary .button {
    background-color: #1779ba;
    color: #fefefe; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #126195;
      color: #fefefe; }
  .button-group.secondary .button {
    background-color: #767676;
    color: #fefefe; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #5e5e5e;
      color: #fefefe; }
  .button-group.success .button {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button-group.warning .button {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button-group.alert .button {
    background-color: #cc4b37;
    color: #fefefe; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #a53b2a;
      color: #fefefe; }
  .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
    width: 100%; }
    .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
      margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .button-group.stacked-for-small .button {
      width: auto;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    .button-group.stacked-for-medium .button {
      width: auto;
      margin-bottom: 0; } }
  @media screen and (max-width: 39.9375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(10, 10, 10, 0.25);
  border-radius: 0;
  background-color: white;
  color: #0a0a0a; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #d7ecfa;
    color: #0a0a0a; }
  .callout.secondary {
    background-color: #eaeaea;
    color: #0a0a0a; }
  .callout.success {
    background-color: #e1faea;
    color: #0a0a0a; }
  .callout.warning {
    background-color: #fff3d9;
    color: #0a0a0a; }
  .callout.alert {
    background-color: #f7e4e1;
    color: #0a0a0a; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.card {
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  background: #fefefe;
  box-shadow: none;
  overflow: hidden;
  color: #0a0a0a; }
  .card > :last-child {
    margin-bottom: 0; }

.card-divider {
  padding: 1rem;
  background: #e6e6e6; }
  .card-divider > :last-child {
    margin-bottom: 0; }

.card-section {
  padding: 1rem; }
  .card-section > :last-child {
    margin-bottom: 0; }

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #0a0a0a; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  margin: 0;
  list-style-type: none; }
  .menu > li {
    display: table-cell;
    vertical-align: middle; }
    [data-whatinput='mouse'] .menu > li {
      outline: 0; }
  .menu > li > a {
    display: block;
    padding: 0.7rem 1rem;
    line-height: 1; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    vertical-align: middle; }
    .menu > li > a img + span,
    .menu > li > a i + span,
    .menu > li > a svg + span {
      vertical-align: middle; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    margin-right: 0.25rem;
    display: inline-block; }
  .menu > li, .menu.horizontal > li {
    display: table-cell; }
  .menu.expanded {
    display: table;
    width: 100%;
    table-layout: fixed; }
    .menu.expanded > li:first-child:last-child {
      width: 100%; }
  .menu.vertical > li {
    display: block; }
  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal > li {
      display: table-cell; }
    .menu.medium-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.medium-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.medium-vertical > li {
      display: block; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal > li {
      display: table-cell; }
    .menu.large-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.large-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.large-vertical > li {
      display: block; } }
  .menu.simple li {
    display: inline-block;
    vertical-align: top;
    line-height: 1; }
  .menu.simple a {
    padding: 0; }
  .menu.simple li {
    margin-left: 0;
    margin-right: 1rem; }
  .menu.simple.align-right li {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.align-right::before, .menu.align-right::after {
    display: table;
    content: ' '; }
  .menu.align-right::after {
    clear: both; }
  .menu.align-right > li {
    float: right; }
  .menu.icon-top > li > a {
    text-align: center; }
    .menu.icon-top > li > a img,
    .menu.icon-top > li > a i,
    .menu.icon-top > li > a svg {
      display: block;
      margin: 0 auto 0.25rem; }
  .menu.icon-top.vertical a > span {
    margin: auto; }
  .menu.nested {
    margin-left: 1rem; }
  .menu .active > a {
    background: #1779ba;
    color: #fefefe; }
  .menu.menu-bordered li {
    border: 1px solid #e6e6e6; }
    .menu.menu-bordered li:not(:first-child) {
      border-top: 0; }
  .menu.menu-hover li:hover {
    background-color: #e6e6e6; }

.menu-text {
  padding-top: 0;
  padding-bottom: 0;
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit; }

.menu-centered {
  text-align: center; }
  .menu-centered > .menu {
    display: inline-block;
    vertical-align: top; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #fefefe;
    box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe;
    content: ''; }
  .menu-icon:hover::after {
    background: #cacaca;
    box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #0a0a0a;
    box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #8a8a8a;
    box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fefefe;
  transition: transform 0.15s linear; }
  .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }

.drilldown-submenu-cover-previous {
  min-height: 100%; }

.is-drilldown-submenu-parent > a {
  position: relative; }
  .is-drilldown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba;
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem; }

.js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  display: block;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-size: 1rem; }
  .dropdown-pane.is-open {
    visibility: visible; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #1779ba transparent transparent;
  right: 5px;
  margin-top: -3px; }

[data-whatinput='mouse'] .dropdown.menu a {
  outline: 0; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1779ba; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fefefe; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #1779ba;
  color: #fefefe; }
  .label.primary {
    background: #1779ba;
    color: #fefefe; }
  .label.secondary {
    background: #767676;
    color: #fefefe; }
  .label.success {
    background: #3adb76;
    color: #0a0a0a; }
  .label.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .label.alert {
    background: #cc4b37;
    color: #fefefe; }

.media-object {
  display: block;
  margin-bottom: 1rem; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      display: block; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  display: table-cell;
  vertical-align: top; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.middle {
    vertical-align: middle; }
  .media-object-section.bottom {
    vertical-align: bottom; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(254, 254, 254, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 1;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-overlap {
    z-index: 10; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 1;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 10; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  transform: translateX(-250px);
  overflow-y: auto; }
  .position-left.is-open ~ .off-canvas-content {
    transform: translateX(250px); }
  .position-left.is-transition-push::after {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " "; }
  .position-left.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-right {
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  transform: translateX(250px);
  overflow-y: auto; }
  .position-right.is-open ~ .off-canvas-content {
    transform: translateX(-250px); }
  .position-right.is-transition-push::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " "; }
  .position-right.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  transform: translateY(-250px);
  overflow-x: auto; }
  .position-top.is-open ~ .off-canvas-content {
    transform: translateY(250px); }
  .position-top.is-transition-push::after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " "; }
  .position-top.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 250px;
  transform: translateY(250px);
  overflow-x: auto; }
  .position-bottom.is-open ~ .off-canvas-content {
    transform: translateY(-250px); }
  .position-bottom.is-transition-push::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 100%;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " "; }
  .position-bottom.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.off-canvas-content {
  transition: transform 0.5s ease;
  backface-visibility: hidden; }

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 250px; } }

.orbit {
  position: relative; }

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }

.orbit-slide {
  width: 100%; }
  .orbit-slide.no-motionui.is-active {
    top: 0;
    left: 0; }

.orbit-figure {
  margin: 0; }

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0; }

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(10, 10, 10, 0.5);
  color: #fefefe; }

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #fefefe; }
  [data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
    outline: 0; }
  .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: rgba(10, 10, 10, 0.5); }

.orbit-previous {
  left: 0; }

.orbit-next {
  left: auto;
  right: 0; }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center; }
  [data-whatinput='mouse'] .orbit-bullets {
    outline: 0; }
  .orbit-bullets button {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.1rem;
    border-radius: 50%;
    background-color: #cacaca; }
    .orbit-bullets button:hover {
      background-color: #8a8a8a; }
    .orbit-bullets button.is-active {
      background-color: #8a8a8a; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' '; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 0;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 40em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
    color: #0a0a0a; }
    .pagination a:hover,
    .pagination button:hover {
      background: #e6e6e6; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #1779ba;
    color: #fefefe;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #cacaca;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #0a0a0a; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #cacaca; }
  .progress.primary .progress-meter {
    background-color: #1779ba; }
  .progress.secondary .progress-meter {
    background-color: #767676; }
  .progress.success .progress-meter {
    background-color: #3adb76; }
  .progress.warning .progress-meter {
    background-color: #ffae00; }
  .progress.alert .progress-meter {
    background-color: #cc4b37; }

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #1779ba; }

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fefefe;
  white-space: nowrap; }

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none; }

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out; }
  .slider-fill.is-dragging {
    transition: all 0s linear; }

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #1779ba;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation; }
  [data-whatinput='mouse'] .slider-handle {
    outline: 0; }
  .slider-handle:hover {
    background-color: #14679e; }
  .slider-handle.is-dragging {
    transition: all 0s linear; }

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1); }
  .slider.vertical .slider-fill {
    top: 0;
    width: 0.5rem;
    max-height: 100%; }
  .slider.vertical .slider-handle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateX(-50%); }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      min-height: 0; } }
  .reveal .column, .reveal .columns {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      width: 600px;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal .reveal {
      right: auto;
      left: auto;
      margin: 0 auto; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal.tiny {
      width: 30%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.small {
      width: 50%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.large {
      width: 90%;
      max-width: 75rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 39.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fefefe;
  user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .switch-paddle {
    margin: 0; }
  .switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    border-radius: 0;
    background: #fefefe;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .switch-paddle {
    background: #1779ba; }
    input:checked ~ .switch-paddle::after {
      left: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .switch-paddle {
    outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }
  input:checked + label > .switch-active {
    display: block; }

.switch-inactive {
  right: 15%; }
  input:checked + label > .switch-inactive {
    display: none; }

.switch.tiny {
  height: 1.5rem; }
  .switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: 0.625rem; }
  .switch.tiny .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem; }
  .switch.tiny input:checked ~ .switch-paddle::after {
    left: 1.75rem; }

.switch.small {
  height: 1.75rem; }
  .switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: 0.75rem; }
  .switch.small .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switch.small input:checked ~ .switch-paddle::after {
    left: 2rem; }

.switch.large {
  height: 2.5rem; }
  .switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem; }
  .switch.large .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 2rem;
    height: 2rem; }
  .switch.large input:checked ~ .switch-paddle::after {
    left: 2.75rem; }

table {
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  thead,
  tbody,
  tfoot {
    border: 1px solid #f1f1f1;
    background-color: #fefefe; }
  caption {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold; }
  thead {
    background: #f8f8f8;
    color: #0a0a0a; }
  tfoot {
    background: #f1f1f1;
    color: #0a0a0a; }
  thead tr,
  tfoot tr {
    background: transparent; }
  thead th,
  thead td,
  tfoot th,
  tfoot td {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold;
    text-align: left; }
  tbody th,
  tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }
  tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #f1f1f1; }
  table.unstriped tbody {
    background-color: #fefefe; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #f1f1f1;
      background-color: #fefefe; }

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #f3f3f3; }

table.hover tfoot tr:hover {
  background-color: #ececec; }

table.hover tbody tr:hover {
  background-color: #f9f9f9; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ececec; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #fefefe;
  list-style-type: none; }
  .tabs::before, .tabs::after {
    display: table;
    content: ' '; }
  .tabs::after {
    clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #1779ba; }
  .tabs.primary > li > a {
    color: #fefefe; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #1673b1; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 1.25rem 1.5rem;
    font-size: 0.75rem;
    line-height: 1;
    color: #1779ba; }
    .tabs-title > a:hover {
      background: #fefefe;
      color: #1468a0; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #e6e6e6;
      color: #1779ba; }

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #fefefe;
  color: #0a0a0a;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 1rem; }
  .tabs-panel[aria-hidden="false"] {
    display: block; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fefefe;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(23, 121, 186, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.title-bar {
  padding: 0.5rem;
  background: #0a0a0a;
  color: #fefefe; }
  .title-bar::before, .title-bar::after {
    display: table;
    content: ' '; }
  .title-bar::after {
    clear: both; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left {
  float: left; }

.title-bar-right {
  float: right;
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #0a0a0a;
  font-size: 80%;
  color: #fefefe; }
  .tooltip::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #0a0a0a;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0a0a0a transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0a0a0a;
    top: 50%;
    bottom: auto;
    left: 100%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0a0a0a transparent transparent;
    top: 50%;
    right: 100%;
    bottom: auto;
    left: auto;
    transform: translateY(-50%); }

.top-bar {
  padding: 0.5rem; }
  .top-bar::before, .top-bar::after {
    display: table;
    content: ' '; }
  .top-bar::after {
    clear: both; }
  .top-bar,
  .top-bar ul {
    background-color: #e6e6e6; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    width: 100%; }
  @media print, screen and (min-width: 40em) {
    .top-bar .top-bar-left,
    .top-bar .top-bar-right {
      width: auto; } }
  @media screen and (max-width: 63.9375em) {
    .top-bar.stacked-for-medium .top-bar-left,
    .top-bar.stacked-for-medium .top-bar-right {
      width: 100%; } }
  @media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large .top-bar-left,
    .top-bar.stacked-for-large .top-bar-right {
      width: 100%; } }

.top-bar-title {
  display: inline-block;
  float: left;
  padding: 0.5rem 1rem 0.5rem 0; }
  .top-bar-title .menu-icon {
    bottom: 2px; }

.top-bar-left {
  float: left; }

.top-bar-right {
  float: right; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' '; }

.clearfix::after {
  clear: both; }

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%); }

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%); }

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%); }

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%); }

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.ease-in {
  transition-timing-function: ease-in !important; }

.ease-out {
  transition-timing-function: ease-out !important; }

.ease-in-out {
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

.shake {
  animation-name: shake-7; }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); } }

.spin-cw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn); }
  100% {
    transform: rotate(0); } }

.spin-ccw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(1turn); } }

.wiggle {
  animation-name: wiggle-7deg; }

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    transform: rotate(0); } }

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms; }

.infinite {
  animation-iteration-count: infinite; }

.slow {
  animation-duration: 750ms !important; }

.fast {
  animation-duration: 250ms !important; }

.linear {
  animation-timing-function: linear !important; }

.ease {
  animation-timing-function: ease !important; }

.ease-in {
  animation-timing-function: ease-in !important; }

.ease-out {
  animation-timing-function: ease-out !important; }

.ease-in-out {
  animation-timing-function: ease-in-out !important; }

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  animation-delay: 300ms !important; }

.long-delay {
  animation-delay: 700ms !important; }

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0); }

ul {
  margin-left: auto; }

body {
  color: #636363;
  font-family: '';
  font-weight: 300;
  /*@include font-size();*/ }

main {
  display: block; }

.h1_hidden {
  font-size: 0px !important;
  text-indent: -99999999px !important; }

.slick-prev:before,
.slick-next:before {
  color: #ddbb0b; }

.slick-next {
  right: 0px;
  top: 30%; }

.slick-prev {
  left: 0px;
  top: 30%; }

.slick-slide {
  outline: none; }

.bold {
  font-weight: 700; }

.row_100 {
  max-width: 80% !important; }

.row_200 {
  max-width: 100% !important; }

.width_100 {
  width: 100% !important; }

.aligne_center {
  text-align: center; }

.margin_section {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.padding_section {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.clear {
  clear: both; }

.parent {
  position: relative;
  width: 50%;
  height: 200px;
  margin: 20px auto; }

.child {
  padding: 20px; }
  .child.both {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .child.horizontal {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0); }
  .child.vertical {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%); }

h1 {
  /*@include font-size();
        font-family: '';
        color: ;*/ }

h2 {
  /*@include font-size();
        font-family: '';
        text-transform: ;
        color:;*/ }

h3 {
  /*@include font-size();
        font-family: '';
        color: ;*/ }

h4 {
  /*@include font-size();
        font-family: '';*/ }

h5 {
  /*@include font-size();
        font-family: '';*/ }

li {
  list-style: none !important; }

#404 header {
  min-height: 100%; }

#container {
  overflow: hidden !important; }

#cookieChoiceInfo {
  top: auto !important;
  bottom: 0 !important;
  z-index: 9999999 !important;
  font-size: 16px;
  font-family: aleo-light, sans-serif !important; }

/*1.5 - perfect fifth
Base Size 22px = 2.2rem
*/
/* Selection */
::-webkit-selection {
  background: #ddbb0b;
  color: white; }

::-moz-selection {
  background: #ddbb0b;
  color: white; }

::-o-selection {
  background: #ddbb0b;
  color: white; }

::-ms-selection {
  background: #ddbb0b;
  color: white; }

::selection {
  background: #ddbb0b;
  color: white; }

/* END Selection */
/* ----------------------------------------------------- FONT ----------------------------------------------------- */
@font-face {
  font-family: 'aleo-bold';
  src: url("../font/aleo/aleo-bold-webfont.eot");
  src: url("../font/aleo/aleo-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../font/aleo/aleo-bold-webfont.woff") format("woff"), url("../font/aleo/aleo-bold-webfont.ttf") format("truetype"), url("../font/aleo/aleo-bold-webfont.svg#aleo-bold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'aleo-light';
  src: url("../font/aleo/aleo-light-webfont.eot");
  src: url("../font/aleo/aleo-light-webfont.eot?#iefix") format("embedded-opentype"), url("../font/aleo/aleo-light-webfont.woff") format("woff"), url("../font/aleo/aleo-light-webfont.ttf") format("truetype"), url("../font/aleo/aleo-light-webfont.svg#aleo-light") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'aleo-regular';
  src: url("../font/aleo/aleo-regular-webfont.eot");
  src: url("../font/aleo/aleo-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../font/aleo/aleo-regular-webfont.woff") format("woff"), url("../font/aleo/aleo-regular-webfont.ttf") format("truetype"), url("../font/aleo/aleo-regular-webfont.svg#aleo-regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'lato-light';
  src: url("../font/lato/lato-light-webfont.eot");
  src: url("../font/lato/lato-light-webfont.eot?#iefix") format("embedded-opentype"), url("../font/lato/lato-light-webfont.woff") format("woff"), url("../font/lato/lato-light-webfont.ttf") format("truetype"), url("../font/lato/lato-light-webfont.svg#lato-light") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'lato';
  src: url("../font/lato/lato-regular-webfont.eot");
  src: url("../font/lato/lato-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../font/lato/lato-regular-webfont.woff") format("woff"), url("../font/lato/lato-regular-webfont.ttf") format("truetype"), url("../font/lato/lato-regular-webfont.svg#lato-regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'lato-bold';
  src: url("../font/lato/lato-bold-webfont.eot");
  src: url("../font/lato/lato-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../font/lato/lato-bold-webfont.woff") format("woff"), url("../font/lato/lato-bold-webfont.ttf") format("truetype"), url("../font/lato/lato-bold-webfont.svg#lato-bold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'lato-black';
  src: url("../font/lato/lato-black-webfont.eot");
  src: url("../font/lato/lato-black-webfont.eot?#iefix") format("embedded-opentype"), url("../font/lato/lato-black-webfont.woff") format("woff"), url("../font/lato/lato-black-webfont.ttf") format("truetype"), url("../font/lato/lato-black-webfont.svg#lato-black") format("svg");
  font-weight: normal;
  font-style: normal; }

/* ----------------------------------------------------- END FONT ----------------------------------------------------- */
html {
  font-size: 62.5%; }

body {
  font-family: aleo-light, sans-serif;
  color: #666;
  line-height: 1.45;
  font-weight: normal;
  width: 100%;
  font-size: 2.2rem; }

header {
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transition: -webkit-transform .3s ease .2s;
  -moz-transition: -moz-transform .3s ease .2s;
  -ms-transition: -moz-transform .3s ease .2s;
  -o-transition: -o-transform .3s ease .2s;
  transition: transform .3s ease .2s;
  width: 100%;
  height: 80px;
  margin: 0 auto;
  background: white;
  -webkit-box-shadow: inset 0 -6px 2px -6px #aaa;
  -moz-box-shadow: inset 0 -6px 2px -6px #aaa;
  -ms-box-shadow: inset 0 -6px 2px -6px #aaa;
  box-shadow: inset 0 -6px 2px -6px #aaa;
  z-index: 9999999; }

.hideheader {
  -webkit-transform: translate(0, -100%);
  -moz-transform: translate(0, -100%);
  -ms-transform: translate(0, -100%);
  -o-transform: translate(0, -100%);
  transform: translate(0, -100%);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden; }

#content-header {
  width: 85%;
  max-width: 1280px;
  margin: 0 auto; }

main {
  width: 100%;
  background-color: white;
  display: block;
  margin: 0 auto;
  z-index: 999999; }

a {
  text-decoration: none;
  border-bottom: 1px solid #ddbb0b;
  padding-bottom: 1px;
  cursor: pointer;
  color: inherit; }

a:hover {
  border-bottom: 1px dotted #ddbb0b;
  color: #ddbb0b; }

a:visited, a:focus {
  color: #666; }

.active {
  border-bottom: 1px solid #ddbb0b;
  color: #ddbb0b; }

p {
  margin-bottom: 3.1rem; }

h1, h2, h3, h4 {
  font-family: lato-light, sans-serif;
  margin: 0 0 2.2rem;
  font-weight: inherit;
  line-height: 1.2; }

h1 {
  text-transform: uppercase; }

h2 {
  font-size: 7.425rem;
  color: #000; }

h3 {
  font-family: lato-bold, sans-serif;
  font-size: 3.3rem;
  text-transform: uppercase;
  color: #000; }

h4 {
  font-family: lato-bold, sans-serif;
  font-size: 2.2rem; }

.section-gris, .section-blanc {
  -webkit-box-shadow: inset 0 -6px 2px -6px #aaa;
  -moz-box-shadow: inset 0 -6px 2px -6px #aaa;
  box-shadow: inset 0 -6px 2px -6px #aaa; }

/* BORDERBOX */
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  hyphens: none;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -o-hyphens: none;
  -ms-hyphens: none; }

/* END BORDERBOX */
strong {
  font-weight: normal; }

/*HTML5 pour IE*/
header, footer, article, section, nav, menu, hgroup, em {
  display: block; }

/* END HTML5 pour IE*/
/* ----------------------------------------------------- BOUTON HEXAGONE ----------------------------------------------------- */
.button {
  font-family: lato-light;
  padding: 10px 25px;
  display: inline-block;
  text-align: center;
  border: 1px solid #ddbb0b;
  position: relative;
  height: 4.6rem;
  background: #ddbb0b;
  z-index: 1;
  color: #ffffff;
  cursor: pointer;
  -webkit-transition: background .3s;
  -moz-transition: background .3s;
  -ms-transition: background .3s;
  -o-transition: background .3s;
  transition: background .3s;
  margin: 0 auto;
  text-transform: uppercase;
  font-size: 1.83335rem; }
  .button:visited, .button:focus {
    color: #ffffff; }

.button:hover {
  background: #ffffff;
  border: 1px solid #ddbb0b;
  color: #ddbb0b; }

#bouton-home {
  margin-top: 20px; }

/* ----------------------------------------------------- END BOUTON HEXAGONE ----------------------------------------------------- */
/* ----------------------------------------------------- BLOC DE TIERS ----------------------------------------------------- */
.tiers1 {
  width: 32%; }

.tiers2 {
  width: 66%; }

.tiers2-inter {
  width: 48.4%; }

/* ----------------------------------------------------- END BLOC DE TIERS ----------------------------------------------------- */
/* ----------------------------------------------------- LOGO ----------------------------------------------------- */
#logo {
  margin: 0;
  position: absolute;
  top: 50%;
  margin-top: -25px;
  overflow: hidden;
  width: 251px;
  height: 50px;
  font-family: lato; }

#logo svg {
  position: relative;
  z-index: 1000; }

#logo a > span {
  position: absolute;
  left: 35px;
  overflow: hidden;
  text-indent: -251px;
  -webkit-transition: text-indent .6s ease-out;
  -moz-transition: text-indent .6s ease-out;
  -ms-transition: text-indent .6s ease-out;
  -o-transition: text-indent .6s ease-out;
  transition: text-indent .6s ease-out; }

#logo:hover a > span {
  text-indent: 0px;
  padding-left: 24px; }

#logo a {
  overflow: hidden;
  line-height: 50px;
  border-bottom: none;
  display: inline-block;
  position: relative;
  width: 100%;
  color: #666;
  font-size: 22px; }

.color-span {
  color: #000; }

#logo svg {
  -webkit-transform: rotate(0deg);
  -webkit-transition: .66s; }

#logo svg:hover {
  -webkit-transform: rotate(360deg);
  -webkit-transition: .66s; }

.txt_circle {
  animation: spinZ 70s linear infinite;
  transform-origin: center; }

@keyframes spinZ {
  0% {
    transform: rotateZ(0deg); }
  100% {
    transform: rotateZ(360deg); } }

.st0 {
  fill: #1D1D1B; }

.st1 {
  fill: #ddbb0b; }

.st2 {
  fill: none;
  stroke: #414140;
  stroke-width: 10.3097;
  stroke-miterlimit: 10; }

.st3 {
  fill: none;
  stroke: #414140;
  stroke-width: 18.5575;
  stroke-miterlimit: 10; }

.st4 {
  fill: #FFFFFF; }

/* ----------------------------------------------------- END LOGO ------------------------------------------------- */
/* ===================== NAVIGATION ============================================= */
nav {
  position: relative;
  font-family: lato-light, sans-serif;
  font-size: 1.4667rem; }

nav ul {
  display: block;
  padding-left: 0;
  margin: 0; }

nav ul li {
  display: inline-block;
  margin-left: 45px;
  line-height: 80px; }

nav ul li:first-child {
  margin-left: 0; }

nav ul li a {
  border: none;
  text-transform: uppercase; }

.nav-active {
  border-bottom: 1px solid #ddbb0b; }

/* ===================== END NAVIGATION ============================================= */
/* ===================== SECTION INTRO ============================================= */
#intro .section-inter h2 {
  margin-top: 97px;
  font-family: aleo-regular;
  color: #666;
  font-size: 3.3rem; }

#intro2 .section-inter h2 {
  margin-top: 128px;
  font-family: aleo-regular;
  color: #666;
  font-size: 3.3rem; }

.titre-strong {
  font-size: 7.425rem;
  color: #000;
  font-family: lato-black;
  line-height: 1.2; }

#intro div .titre-section-intro .titre-strong {
  text-transform: uppercase; }

.logo_julien_watelet {
  margin-top: 97px;
  max-width: 200px;
  float: left;
  margin-right: 40px; }

/* ===================== END SECTION INTRO ============================================= */
/* ===================== SECTION MOI ============================================= */
#calque-trace {
  position: fixed;
  right: 3.5%;
  bottom: 1px;
  width: 45%; }

.photo-moi {
  position: fixed;
  right: 3.5%;
  bottom: 1px;
  width: 45%;
  max-width: 700px; }

/* ===================== effet avec waypoint sur photo de moi ============================================= */
.no-js .photo-moi, .no-js #calque-trace {
  position: absolute; }

.no-fixed .photo-moi, .no-fixed #calque-trace {
  position: absolute; }

.chemin {
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  -webkit-animation: dash 3s linear alternate 2 forwards;
  -moz-animation: dash 3s linear alternate 2 forwards;
  -ms-animation: dash 3s linear alternate 2 forwards;
  -o-animation: dash 3s linear alternate 2 forwards;
  animation: dash 3s linear alternate 2 forwards; }

@-webkit-keyframes dash {
  from {
    stroke-dashoffset: 2000; }
  to {
    stroke-dashoffset: 0; } }

@-ms-keyframes dash {
  from {
    stroke-dashoffset: 2000; }
  to {
    stroke-dashoffset: 0; } }

@keyframes dash {
  from {
    stroke-dashoffset: 2000; }
  to {
    stroke-dashoffset: 0; } }

.photo-moi {
  opacity: 0;
  -webkit-animation: opacity-anim .5s linear 1 forwards;
  -moz-animation: opacity-anim .5s linear 1 forwards;
  -ms-animation: opacity-anim .5s linear 1 forwards;
  -o-animation: opacity-anim .5s linear 1 forwards;
  animation: opacity-anim .5s linear 1 forwards;
  -webkit-animation-delay: 2.8s;
  -moz-animation-delay: 2.8s;
  -o-animation-delay: 2.8s;
  animation-delay: 2.8s; }

@-webkit-keyframes opacity-anim {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-ms-keyframes opacity-anim {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes opacity-anim {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/* ===================== END effet avec waypoint sur photo de moi ============================================= */
/* ===================== END SECTION MOI ============================================= */
section {
  display: block;
  position: relative;
  overflow: hidden; }

.section-gris {
  padding-bottom: 150px;
  padding-top: 150px;
  background-color: #fafafa; }

.section-blanc {
  padding-bottom: 150px;
  padding-top: 150px; }

.section-inter {
  margin: 0 auto;
  width: 85%;
  max-width: 1280px; }

/* ===================== LISTE DE TRAVAUX RECENT ============================================= */
#travaux .section-inter div p a, #moi .section-inter div p a, #link-travaux .section-inter div a, #autres-travaux .section-inter div a {
  cursor: url("../img/externallink.png"), pointer; }

#link-travaux .section-inter div .button {
  cursor: pointer; }

#element-travaux {
  margin-top: -111px; }

main #autres-travaux .section-inter li a {
  cursor: url("../img/externallink.png"), pointer; }

main #autres-travaux .section-inter li:hover a {
  cursor: url("../img/externallink.png"), pointer; }

#autres-travaux .section-inter li {
  float: left;
  display: block;
  height: 100%;
  position: relative;
  overflow: hidden;
  border: 1px solid #000;
  margin-top: 20px; }

#autres-travaux .section-inter .li-centre {
  margin: 2% 2% 0 2%; }

#autres-travaux .section-inter li:last-child {
  margin-right: 0; }

main #autres-travaux .section-inter li a img {
  width: 100%;
  display: block;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  -o-transition: .5s;
  transition: .5s; }

main #autres-travaux .section-inter li:hover a img {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2); }

main #autres-travaux .section-inter li div {
  position: absolute;
  top: 0%;
  left: 0%;
  opacity: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  -o-transition: .5s;
  transition: .5s; }

main #autres-travaux .section-inter li:hover div {
  background: rgba(255, 255, 255, 0.9);
  opacity: 1; }

#autres-travaux .titre-travaux-recent {
  text-align: center;
  padding: 5%;
  background: #000;
  color: white;
  margin-top: -25%;
  margin-bottom: 0;
  font-family: lato-light, sans-serif;
  text-transform: uppercase;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  font-size: 2.2rem; }

#autres-travaux li:hover .titre-travaux-recent {
  margin-top: -2px; }

#autres-travaux .text-travaux-recent {
  margin-bottom: 0;
  margin-top: 120%;
  padding: 20% 0;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-align: center; }

#autres-travaux .text-travaux-recent h4 {
  font-family: lato-bold, sans-serif;
  text-transform: uppercase;
  color: #666;
  margin-bottom: 10px; }

#autres-travaux .text-travaux-recent p {
  font-family: aleo-light, sans-serif;
  color: #666;
  font-size: 1.4667rem;
  font-style: italic; }

#autres-travaux .text-travaux-recent h4::after {
  content: "";
  width: 120px;
  border-bottom: 1px solid #ddbb0b;
  display: block;
  margin: 12px auto 0; }

#autres-travaux li:hover .text-travaux-recent {
  margin-top: 35%;
  margin-top: -2px; }

/* ===================== END LISTE DE TRAVAUX RECENT ============================================= */
/* footer ---------------------------------------------------------------*/
footer {
  clear: both;
  height: 55px;
  background-color: white;
  font-size: 1.4667rem;
  font-family: lato-light; }

footer a:hover {
  border-bottom: none; }

.content-footer {
  width: 85%;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 15px; }

.content-footer ul {
  list-style-type: none;
  padding-left: 0 !important; }

footer ul li a {
  border: none; }

.social-ul {
  margin: 0 auto;
  margin-top: 12px;
  float: right; }

.social-ul li {
  display: inline-block;
  margin: 0 9px;
  font-family: lato-light, sans-serif;
  line-height: 1.8rem; }

.social-ul li.retour {
  margin: 0 0 0 9px; }

.social-ul li.contact, .social-ul li.twitter, .social-ul li.googleplus, .social-ul li.linkedin, .social-ul li.behance {
  cursor: url("../img/externallink.png"), pointer; }

.social-ul li.contact a {
  background: url("../img/sprite-social.png") no-repeat 0 0;
  width: 20px;
  height: 15px; }

.social-ul li.twitter a {
  background: url("../img/sprite-social.png") no-repeat 25% 0;
  width: 19px;
  height: 15px; }

.social-ul li.googleplus a {
  background: url("../img/sprite-social.png") no-repeat 49.686% 0;
  width: 16px;
  height: 15px; }

.social-ul li.linkedin a {
  background: url("../img/sprite-social.png") no-repeat 73.75% 0;
  width: 15px;
  height: 15px; }

.social-ul li.behance a {
  background: url("../img/sprite-social.png") no-repeat 100% 0;
  width: 23px;
  height: 15px; }

.social-ul li.contact:hover a, .social-ul li.twitter:hover a, .social-ul li.googleplus:hover a, .social-ul li.linkedin:hover a, .social-ul li.behance:hover a {
  cursor: url("../img/externallink.png"), pointer; }

.social-ul li.contact:hover a {
  background: url("../img/sprite-social.png") no-repeat 0 100%;
  width: 20px;
  height: 15px; }

.social-ul li.twitter:hover a {
  background: url("../img/sprite-social.png") no-repeat 25% 100%;
  width: 19px;
  height: 15px; }

.social-ul li.googleplus:hover a {
  background: url("../img/sprite-social.png") no-repeat 49.686% 100%;
  width: 16px;
  height: 15px; }

.social-ul li.linkedin:hover a {
  background: url("../img/sprite-social.png") no-repeat 73.75% 100%;
  width: 15px;
  height: 15px; }

.social-ul li.behance:hover a {
  background: url("../img/sprite-social.png") no-repeat 100% 100%;
  width: 23px;
  height: 15px; }

.social-ul li.contact a, .social-ul li.twitter a, .social-ul li.googleplus a, .social-ul li.linkedin a, .social-ul li.behance a {
  display: inline-block;
  text-indent: -9999px; }

/* end footer ---------------------------------------------------------------*/
/* ================ Page contact ======================================================== */
#contact .section-inter h2 {
  font-family: aleo-regular;
  color: #666;
  font-size: 3.3rem; }

.maps {
  margin-top: 85px;
  background: url(../img/map.jpg);
  background-position-x: 70%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-size: 1.4667rem;
  height: 395px;
  position: relative; }

.no-js .maps {
  border: 1px solid #000; }

.coordonnee {
  text-align: center;
  list-style: none;
  padding: 5px 25px 25px 25px;
  border: 1px solid #000;
  background: rgba(255, 255, 255, 0.9);
  display: block;
  float: right;
  margin-right: 80px;
  margin-top: 80px;
  position: absolute;
  z-index: 9999;
  right: 0; }

.coordonnee li {
  margin-top: 20px; }

.coordonnee li a {
  border: none;
  font-family: aleo-bold; }

#map-canvas {
  height: 395px;
  border: 1px solid #000; }

/* ================ Formulaire ======================================================== */
.cont-form {
  font-family: lato-light; }

input {
  border: 1px solid #000 !important;
  padding: 10px !important;
  display: initial !important;
  height: auto !important;
  outline: 0;
  color: black;
  font-size: 1.4667rem !important;
  width: 100% !important;
  margin: 15px auto !important;
  font-family: lato-light !important;
  transition: all 0.1s ease-in-out; }

input:focus {
  border: 2px solid #000 !important;
  font-family: lato-bold !important;
  padding: 10px; }

.active-floatlabel {
  padding-top: 22px !important; }

textarea {
  height: 100px;
  width: 100%;
  border: 1px solid #000;
  padding: 10px;
  outline: 0;
  color: black;
  font-size: 1.4667rem !important;
  font-family: lato-light;
  margin: 15px auto; }

textarea:focus {
  border: 2px solid #000;
  font-family: lato-bold; }

#button, input[type="submit"] {
  color: white;
  background: #ddbb0b;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  cursor: pointer;
  font-family: lato-light;
  text-transform: uppercase;
  border: solid #ddbb0b; }

#button:focus, input[type="submit"] {
  border: 1px solid #ddbb0b;
  -webkit-animation-name: bounce;
  -moz-animation-name: bounce;
  -ms-animation-name: bounce;
  -o-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation-duration: .8s;
  -moz-animation-duration: .8s;
  -ms-animation-duration: .8s;
  -o-animation-duration: .8s;
  animation-duration: .8s;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-4px); } }

@-ms-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-4px); } }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-4px); } }

#button:hover {
  background: white;
  color: #ddbb0b;
  cursor: pointer; }

input[type="submit"] {
  border: 1px solid #ddbb0b !important; }

input[type="submit"]:hover {
  background: white;
  color: #ddbb0b;
  cursor: pointer;
  border: 1px solid #ddbb0b !important; }

.label-floatlabel {
  font-weight: bold !important;
  font-size: 11px !important;
  margin-top: 13px !important; }

label.red-label {
  color: red !important;
  /*obligatoire pour le label*/ }

.floatlabel_form input[type="submit"] {
  margin-top: 0 !important; }

#error {
  font-family: lato-light;
  font-weight: 300;
  color: #ff7F4E; }

#error p {
  margin-bottom: 0; }

#error ul li {
  list-style: none; }

.errors-display {
  width: 654px;
  margin: 0 auto;
  height: 60px;
  font-size: 1.4667rem;
  margin-top: 10px;
  color: #ff7F4E; }

.errors-display p {
  display: none;
  margin-bottom: 0; }

#contact h2 {
  margin-bottom: 0; }

textarea {
  max-width: 100%;
  max-height: 800px; }

form label {
  display: none;
  color: #000; }

.no-js form label {
  display: block;
  margin-bottom: 0; }

.no-js input {
  margin-top: 5px;
  margin-bottom: 5px; }

.no-js #message {
  margin-top: 5px;
  margin-bottom: 5px; }

.no-js input::-webkit-input-placeholder {
  color: white; }

.no-js input:-moz-placeholder {
  color: white; }

.no-js input::-moz-placeholder {
  color: white; }

.no-js input:-ms-placeholder {
  color: white; }

/* ================ Fin Formulaire ======================================================== */
/* ================ Fin page contact ======================================================== */
.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.clearfix {
  display: block; }

html[xmlns] .clearfix {
  display: block; }

* html .clearfix {
  height: 1%; }

.clear {
  clear: both; }

.float-left {
  float: left; }

.float-right {
  float: right; }

.float-right-menu {
  float: right; }

.float-none {
  float: none; }

.bottom {
  padding-bottom: 0; }

/* ================ Page DATAVIZ ======================================================== */
.acces-site {
  text-align: center; }

.acces-site a {
  cursor: url("../img/externallink.png"), pointer;
  margin-left: 0; }

.acces-site a:nth-child(3) {
  margin-right: 74px; }

.acces-site a:nth-child(4) {
  cursor: pointer; }

.acces-site h3 {
  font-family: lato-bold; }

.acces-site p {
  width: 75%;
  margin: 0 auto 3.1rem auto; }

#element-dataviz {
  position: absolute;
  bottom: 0;
  width: 40%;
  right: 7%;
  max-width: 610px; }

.dataviz img {
  margin-top: 9px; }

/* ================ Fin Page DATAVIZ ======================================================== */
/* ================ Page 404 ======================================================== */
.section-photo-404 {
  padding: 0;
  margin: 0;
  font-size: 0;
  line-height: 0; }

.section-photo-404 img {
  width: 50%; }

cite {
  display: block;
  padding-bottom: 25px; }

q {
  padding-top: 152px;
  display: block; }

/* ================ Fin Page 404 ======================================================== */
/* ================ Page credits ======================================================== */
.credits li {
  list-style: none; }

.credits li a {
  cursor: url("../img/externallink.png"), pointer; }

.credits .tiers1 {
  margin-right: 2%; }

.credits .tiers1:last-child {
  margin-right: 0; }

.credits .button {
  margin-top: 100px; }

/* ================ Fin Page credits ======================================================== */
/* ================ Page politique ======================================================== */
.credits .tiers2-inter {
  margin-right: 2%; }

.credits .tiers2-inter:last-child {
  margin-right: 0; }

/* ================ Fin Page politique ======================================================== */
/* MEDIA QUERIES ============================================================================== */
@media screen and (max-width: 1350px) {
  #link-travaux a {
    margin-bottom: 150px; } }

@media screen and (max-width: 1050px) {
  #autres-travaux .section-inter .li-centre {
    margin: 2% 0 0 0; }
  #autres-travaux .section-inter li {
    margin-top: 2%; }
  #autres-travaux .section-inter li:nth-child(2n) {
    margin-left: 2%; }
  #link-travaux a {
    margin-bottom: 150px; } }

@media screen and (max-width: 900px) {
  .credits .tiers1, .credits .tiers2-inter {
    float: none;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 62px;
    width: 100%; }
  .credits .tiers2-inter {
    float: none;
    text-align: left;
    margin: 0 auto;
    margin-bottom: 62px;
    width: 100%; }
  .credits .tiers1:last-child, .credits .tiers2-inter:last-child {
    margin-right: auto;
    margin-bottom: 0; }
  .credits .button {
    margin-left: 0; }
  #intro .section-inter h2 {
    margin-top: 36px; }
  #intro2 .section-inter h2 {
    margin-top: 36px; }
  #map-canvas {
    display: none; }
  .maps {
    border: 1px solid #000; }
  #element-dataviz {
    width: 30%; } }

@media screen and (max-width: 850px) {
  header {
    height: 130px;
    position: relative; }
  .hideheader {
    -webkit-transform: translate(0, 0%);
    -moz-transform: translate(0, 0%);
    -ms-transform: translate(0, 0%);
    -o-transform: translate(0, 0%);
    transform: translate(0, 0%);
    -webkit-backface-visibility: visible;
    -moz-backface-visibility: visible;
    -ms-backface-visibility: visible;
    -o-backface-visibility: visible;
    backface-visibility: visible; }
  #logo {
    margin-top: 25px;
    left: 50%;
    margin-left: -25px;
    position: relative;
    height: 100%; }
  #logo a {
    line-height: 0; }
  .float-right-menu {
    float: none;
    text-align: center; }
  nav ul li {
    margin-left: 25px;
    line-height: 50px; }
  footer {
    text-align: center;
    height: 130px; }
  .social-ul {
    float: none;
    margin-top: 34px;
    margin-bottom: 20px; }
  .social-ul::after {
    content: "";
    width: 120px;
    border-bottom: 1px dotted #ddbb0b;
    display: block;
    margin: 18px auto 0; }
  .tiers1 {
    width: 100%; }
  .tiers2 {
    width: 100%; }
  .tiers2-inter {
    width: 100%; }
  #link-travaux .button {
    margin-bottom: 60px; }
  #calque-trace {
    display: none; }
  .photo-moi {
    position: absolute; }
  #calque-trace {
    width: 35%; }
  .photo-moi {
    width: 35%; }
  .active {
    border-bottom: none;
    color: #666; } }

@media screen and (max-width: 800px) {
  #autres-travaux .section-inter .li-centre {
    margin: 80px 0 0 0; }
  #autres-travaux .section-inter li {
    width: 100%;
    margin-top: 80px;
    overflow: visible; }
  #autres-travaux .section-inter li:nth-child(2n) {
    margin-left: 0; }
  main #autres-travaux .section-inter li div {
    position: absolute;
    top: 0%;
    left: 0%;
    opacity: 1;
    width: 100%;
    height: 32px;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s; }
  main #autres-travaux .section-inter li:hover a img {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  main #autres-travaux .section-inter li div {
    -webkit-transition: 0;
    -moz-transition: 0;
    -ms-transition: 0;
    -o-transition: 0;
    transition: 0;
    top: 105%; }
  main #autres-travaux .section-inter li:hover div {
    background: none;
    opacity: 1; }
  #autres-travaux .titre-travaux-recent {
    text-align: center;
    padding: 5px;
    background: none;
    color: #666;
    margin-top: 0;
    margin-bottom: 0;
    font-family: lato-light, sans-serif;
    text-transform: uppercase;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    font-size: 2.2rem; }
  #autres-travaux li:hover .titre-travaux-recent {
    margin-top: 0px; }
  #autres-travaux .text-travaux-recent {
    display: none; } }

@media screen and (max-width: 650px) {
  .logo_julien_watelet {
    margin-top: 20px; }
  .float-left {
    float: none; }
  #intro .section-inter h2 {
    margin-top: 20px; }
  #intro2 .section-inter h2 {
    margin-top: 0; }
  .section-gris, .section-blanc {
    padding-top: 75px;
    padding-bottom: 75px; }
  .bottom {
    padding-bottom: 0; }
  #element-travaux {
    margin-top: 0; }
  .maps {
    margin-top: 47px; }
  #element-dataviz {
    width: 20%; }
  .section-photo-404 img {
    width: 100%; } }

@media screen and (max-width: 560px) {
  .logo_julien_watelet {
    margin-top: 20px; }
  .acces-site a:nth-child(3) {
    margin-right: 0;
    margin-bottom: 23px; }
  .acces-site a {
    width: 100%; } }

@media screen and (max-width: 450px) {
  .block_section_intro {
    text-align: center; }
  .block_logo {
    width: 100%;
    display: block;
    position: relative; }
  .logo_julien_watelet {
    margin: 0 auto;
    text-align: center;
    display: block;
    width: 100%;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
  .maps {
    height: 325px; }
  header {
    height: 230px;
    z-index: 0; }
  nav ul li {
    display: block;
    margin-left: 0;
    margin-bottom: 0;
    line-height: 37px; }
  .float-left {
    float: none; }
  #bouton-moi {
    margin-bottom: 35px; }
  .coordonnee {
    margin-right: 0;
    margin-top: 0;
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 0; }
  .coordonnee li {
    margin-top: 46px; }
  h2 {
    font-size: 4.95rem; }
  .titre-strong {
    font-size: 4.95rem; }
  footer {
    height: 170px; } }

/*Solution pour une css internet explorer au dessus de la version 9 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .photo-moi {
    opacity: 1;
    -webkit-animation: opacity-anim 1s linear 1 forwards;
    -moz-animation: opacity-anim 1s linear 1 forwards;
    -ms-animation: opacity-anim 1s linear 1 forwards;
    -o-animation: opacity-anim 1s linear 1 forwards;
    animation: opacity-anim 1s linear 1 forwards;
    -webkit-animation-delay: 0;
    -moz-animation-delay: 0;
    -o-animation-delay: 0;
    animation-delay: 0; } }

/* END MEDIA QUERIES ============================================================================== */
